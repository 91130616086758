<template>
  <nav class="bg-white border-b border-t">
    <div class="max-w-none mx-auto px-2">
      <div class="flex justify-between h-32">
        <div class="flex-auto flex px-2 lg:px-0">
          <div class="flex-shrink-0 flex items-center">
            <router-link :to="{ name: 'Home' }">
              <img class="block lg:hidden md:h-24 h-16 w-auto" src="@/assets/img/pc-logo.png" alt="Workflow" />
              <img class="hidden lg:block h-16 xl:h-24 w-auto" src="@/assets/img/pc-logo.png" alt="Workflow" />
            </router-link>
          </div>
          <div class="hidden lg:ml-6 lg:flex lg:flex-1 lg:space-x-5 justify-evenly">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 
            hover:border-gray-300 hover:text-gray-700" -->
            <router-link
              :to="{ name: 'Home' }"
              class="border-transparent text-gray-500 
            hover:border-gray-300 hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
            >
              Home
            </router-link>

            <Popover class="relative flex" v-slot="{ open }">
              <PopoverButton
                class="border-transparent text-gray-500 hover:border-gray-300 
              hover:text-gray-700 inline-flex 
              items-center px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal focus:outline-none"
              >
                Products
              </PopoverButton>
              <PopoverOverlay class="bg-black" :class="open ? 'opacity-30 fixed inset-0' : 'opacity-0'" />
              <transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="opacity-0 translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition ease-in duration-150"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
              >
                <PopoverPanel
                  v-slot="{ close }"
                  class="absolute z-10 left-1/2 transform -translate-x-20 translate-y-20 
                  mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl"
                >
                  <!-- <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                      <a
                        v-for="item in solutions"
                        :key="item.name"
                        :href="item.href"
                        class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50 
                        transition ease-in-out duration-150"
                      >
                        <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 
                          ounded-md bg-indigo-500 text-white sm:h-12 sm:w-12"
                        >
                          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            {{ item.description }}
                          </p>
                        </div>
                      </a>
                    </div>
                    <div class="p-5 bg-gray-50 sm:p-8">
                      <a
                        href="#"
                        class="-m-3 p-3 flow-root rounded-md hover:bg-gray-100 transition ease-in-out duration-150"
                      >
                        <span class="flex items-center">
                          <span class="text-base font-medium text-gray-900">
                            Enterprise
                          </span>
                          <span
                            class="ml-3 inline-flex items-center px-3 py-0.5 rounded-full 
                            text-xs font-medium leading-5 bg-indigo-100 text-indigo-800"
                          >
                            New
                          </span>
                        </span>
                        <span class="mt-1 block text-sm text-gray-500">
                          Empower your entire team with even more advanced tools.
                        </span>
                      </a>
                    </div>
                  </div> -->

                  <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                      <div href="#" class="-m-3 p-3 flex items-start rounded-lg">
                        <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 
                text-white sm:h-12 sm:w-12"
                        >
                          <font-awesome-icon size="lg" :icon="['fas', 'microchip']" />
                        </div>
                        <div class="ml-4">
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'Products', params: { type: 'dummy-components' } }"
                          >
                            <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                              Dummy Components
                            </p>
                          </router-link>
                          <!-- <router-link
                            @click="accept(close)"
                            :to="{
                              name: 'ProductsListing',
                              params: { type: 'dummy-components', view: 'package-name' },
                            }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              By Package Name
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'dummy-components', view: 'pin-count' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              By Pin Count
                            </p>
                          </router-link> -->
                          <!-- <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'dummy-components', view: 'pitch' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              By Pitch
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'dummy-components', view: 'body-size' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              By Body Size
                            </p>
                          </router-link> -->
                        </div>
                      </div>

                      <div class="-m-3 p-3 flex items-start rounded-lg">
                        <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md 
                bg-indigo-500 text-white sm:h-12 sm:w-12"
                        >
                          <font-awesome-icon rotation="90" size="lg" :icon="['fas', 'keyboard']" />
                        </div>
                        <div class="ml-4">
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'Products', params: { type: 'daisy-chain-wafers' } }"
                          >
                            <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                              Advanced Daisy-Chain Test Die &amp; Wafers
                            </p>
                          </router-link>
                          <!-- <router-link
                            @click="accept(close)"
                            :to="{ name: 'Products', params: { type: 'daisy-chain-wafers' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              Product Matrix
                            </p>
                          </router-link> -->
                        </div>
                      </div>

                      <div class="-m-3 p-3 flex items-start rounded-lg">
                        <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 
                text-white sm:h-12 sm:w-12"
                        >
                          <font-awesome-icon size="lg" :icon="['fas', 'ruler-horizontal']" />
                        </div>
                        <div class="ml-4">
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'Products', params: { type: 'pcb-boards' } }"
                          >
                            <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                              Process Evaluation PCB Boards &amp; Kits
                            </p>
                          </router-link>
                          <!-- <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'pcb-boards', view: 'name' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              By Name
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'pcb-boards', view: 'cleanliness' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              Cleanliness &amp; Residue Evaluation + Conformal Coating
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'pcb-boards', view: 'solder-print' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              Solder Print Test
                            </p>
                          </router-link> -->
                          <!-- <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'pcb-boards', view: 'technology' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              By Technology
                            </p>
                          </router-link> -->
                        </div>
                      </div>

                      <div class="-m-3 p-3 flex items-start rounded-lg">
                        <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 
                text-white sm:h-12 sm:w-12"
                        >
                          <!-- Heroicon name: outline/view-grid -->
                          <font-awesome-icon size="lg" :icon="['fas', 'box']" />
                        </div>
                        <div class="ml-4">
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'Products', params: { type: 'solder-training-kits' } }"
                          >
                            <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                              Solder Training Kits
                            </p>
                          </router-link>
                          <!-- <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'solder-training-kits', view: 'name' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              By Name
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{
                              name: 'ProductsListing',
                              params: { type: 'solder-training-kits', view: 'ipc-standard-kits' },
                            }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              By IPC Standard Kits
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{
                              name: 'ProductsListing',
                              params: { type: 'solder-training-kits', view: 'through-hole-kits' },
                            }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              Through-Hole Kits
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{
                              name: 'ProductsListing',
                              params: { type: 'solder-training-kits', view: 'wire-terminal-kits' },
                            }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              Wire &amp; Terminal Kits
                            </p>
                          </router-link> -->
                        </div>
                      </div>

                      <div class="-m-3 p-3 flex items-start rounded-lg">
                        <div
                          class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 
                text-white sm:h-12 sm:w-12"
                        >
                          <font-awesome-icon size="lg" :icon="['fas', 'tools']" />
                        </div>
                        <div class="ml-4">
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'Products', params: { type: 'training-aids' } }"
                          >
                            <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                              Training Aids, Tools &amp; Supplies
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'training-aids', view: 'training-aids' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              Training Aids
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'training-aids', view: 'tools' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              Tools
                            </p>
                          </router-link>
                          <router-link
                            @click="accept(close)"
                            :to="{ name: 'ProductsListing', params: { type: 'training-aids', view: 'supplies' } }"
                          >
                            <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                              Supplies
                            </p>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="p-5 bg-gray-50 sm:p-8">
                      <a href="#" class="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">
                        <div class="flex items-center">
                          <div class="text-base font-medium text-gray-900">
                            Some New Product To Highlight?
                          </div>
                          <span
                            class="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 
                  bg-indigo-100 text-indigo-800"
                          >
                            New
                          </span>
                        </div>
                        <p class="mt-1 text-sm text-gray-500">
                          [New product tagline here]
                        </p>
                      </a>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

            <router-link
              :to="{ name: 'About' }"
              class="border-transparent text-gray-500 
            hover:border-gray-300 hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
            >
              About
            </router-link>
            <router-link
              :to="{ name: 'Contact' }"
              class="border-transparent text-gray-500 
            hover:border-gray-300 hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
            >
              Contact
            </router-link>
            <router-link
              :to="{ name: 'International Distributors' }"
              class="border-transparent text-gray-500 
            hover:border-gray-300 hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
            >
              Distributors
            </router-link>
            <router-link
              :to="{ name: 'Newsletter' }"
              class="border-transparent text-gray-500 
            hover:border-gray-300 hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
            >
              Newsletter
            </router-link>

            <div class="flex flex-col flex-shrink-0 justify-center text-center items-center space-y-2">
              <div class="text-xl text-gray-500 text-center">
                888-388-7803
              </div>
              <div>
                <label for="search" class="sr-only">Search</label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <!-- Heroicon name: solid/search -->
                    <svg
                      class="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 
                    1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                  <input
                    id="search"
                    name="search"
                    class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md 
                leading-5 bg-white 
                placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 
                focus:ring-indigo-500 
                focus:border-indigo-500 sm:text-sm"
                    placeholder="Search"
                    type="search"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex items-center lg:hidden">
          <!-- Mobile menu button -->
          <button
            type="button"
            @click="showMobileMenu = !showMobileMenu"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 
            hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!-- Icon when menu is closed. -->
            <!--
            Heroicon name: outline/menu

            Menu open: "hidden", Menu closed: "block"
          -->
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <!-- Icon when menu is open. -->
            <!--
            Heroicon name: outline/x

            Menu open: "block", Menu closed: "hidden"
          -->
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="opacity-0 translate-y-1"
    >
      <div class="lg:hidden" id="mobile-menu" v-show="showMobileMenu">
        <div class="flex items-baseline lg:hidden">
          <div class="flex-grow">
            <div class="pt-2 pb-4 px-4 space-y-1">
              <label for="search" class="sr-only">Search</label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <!-- Heroicon name: solid/search -->
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 
                        1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search"
                  name="search"
                  class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md 
                    leading-5 bg-white 
                    placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 
                    focus:ring-indigo-500 
                    focus:border-indigo-500 sm:text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </div>
          </div>
          <div class="flex-grow text-right">
            <div class="px-4 space-y-1 text-2xl text-gray-500">
              888-388-7803
            </div>
          </div>
        </div>
        <div class="pt-2 pb-3 pl-5 space-y-1 flex flex-col">
          <router-link
            :to="{ name: 'Home' }"
            class="border-transparent text-gray-500 
             hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
          >
            Home
          </router-link>
          <button
            @click="showProductsMenu = !showProductsMenu"
            class="border-transparent text-gray-500  hover:text-gray-700 inline-flex 
              items-center px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal focus:outline-none"
            id="menu-products"
          >
            Products
          </button>
          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
          >
            <div
              v-show="showProductsMenu"
              class="absolute z-50 translate-x-1 transform w-screen translate-y-16
                max-w-md"
            >
              <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                  <div href="#" class="-m-3 p-3 flex items-start rounded-lg">
                    <div
                      class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 
                text-white sm:h-12 sm:w-12"
                    >
                      <font-awesome-icon size="lg" :icon="['fas', 'microchip']" />
                    </div>
                    <div class="ml-4">
                      <router-link :to="{ name: 'Products', params: { type: 'dummy-components' } }">
                        <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                          Dummy Components
                        </p>
                      </router-link>
                      <!-- <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'dummy-components', view: 'package-name' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          By Package Name
                        </p>
                      </router-link>
                      <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'dummy-components', view: 'pin-count' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          By Pin Count
                        </p>
                      </router-link>
                      <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'dummy-components', view: 'pitch' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          By Pitch
                        </p>
                      </router-link>
                      <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'dummy-components', view: 'body-size' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          By Body Size
                        </p>
                      </router-link> -->
                    </div>
                  </div>

                  <div class="-m-3 p-3 flex items-start rounded-lg">
                    <div
                      class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md 
                bg-indigo-500 text-white sm:h-12 sm:w-12"
                    >
                      <font-awesome-icon rotation="90" size="lg" :icon="['fas', 'keyboard']" />
                    </div>
                    <div class="ml-4">
                      <router-link :to="{ name: 'Products', params: { type: 'daisy-chain-wafers' } }">
                        <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                          Advanced Daisy-Chain Test Die &amp; Wafers
                        </p>
                      </router-link>
                      <!-- <router-link :to="{ name: 'Products', params: { type: 'daisy-chain-wafers' } }">
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          Product Matrix
                        </p>
                      </router-link> -->
                    </div>
                  </div>

                  <div class="-m-3 p-3 flex items-start rounded-lg">
                    <div
                      class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 
                text-white sm:h-12 sm:w-12"
                    >
                      <font-awesome-icon size="lg" :icon="['fas', 'ruler-horizontal']" />
                    </div>
                    <div class="ml-4">
                      <router-link :to="{ name: 'Products', params: { type: 'pcb-boards' } }">
                        <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                          Process Evaluation PCB Boards &amp; Kits
                        </p>
                      </router-link>
                      <!-- <router-link :to="{ name: 'ProductsListing', params: { type: 'pcb-boards', view: 'name' } }">
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          By Name
                        </p>
                      </router-link>
                      <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'pcb-boards', view: 'cleanliness' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          Cleanliness &amp; Residue Evaluation + Conformal Coating
                        </p>
                      </router-link>
                      <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'pcb-boards', view: 'solder-print' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          Solder Print Test
                        </p>
                      </router-link>
                      <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'pcb-boards', view: 'technology' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          By Technology
                        </p>
                      </router-link> -->
                    </div>
                  </div>

                  <div class="-m-3 p-3 flex items-start rounded-lg">
                    <div
                      class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 
                text-white sm:h-12 sm:w-12"
                    >
                      <!-- Heroicon name: outline/view-grid -->
                      <font-awesome-icon size="lg" :icon="['fas', 'box']" />
                    </div>
                    <div class="ml-4">
                      <router-link :to="{ name: 'Products', params: { type: 'solder-training-kits' } }">
                        <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                          Solder Training Kits
                        </p>
                      </router-link>
                      <!-- <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'solder-training-kits', view: 'name' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          By Name
                        </p>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'ProductsListing',
                          params: { type: 'solder-training-kits', view: 'ipc-standard-kits' },
                        }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          By IPC Standard Kits
                        </p>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'ProductsListing',
                          params: { type: 'solder-training-kits', view: 'through-hole-kits' },
                        }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          Through-Hole Kits
                        </p>
                      </router-link>
                      <router-link
                        :to="{
                          name: 'ProductsListing',
                          params: { type: 'solder-training-kits', view: 'wire-terminal-kits' },
                        }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          Wire &amp; Terminal Kits
                        </p>
                      </router-link> -->
                    </div>
                  </div>

                  <div class="-m-3 p-3 flex items-start rounded-lg">
                    <div
                      class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 
                text-white sm:h-12 sm:w-12"
                    >
                      <font-awesome-icon size="lg" :icon="['fas', 'tools']" />
                    </div>
                    <div class="ml-4">
                      <router-link :to="{ name: 'Products', params: { type: 'training-aids' } }">
                        <p class="text-base font-medium text-gray-900 rounded-md hover:bg-gray-100 p-1 px-2">
                          Training Aids, Tools &amp; Supplies
                        </p>
                      </router-link>
                      <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'training-aids', view: 'training-aids' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          Training Aids
                        </p>
                      </router-link>
                      <router-link :to="{ name: 'ProductsListing', params: { type: 'training-aids', view: 'tools' } }">
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          Tools
                        </p>
                      </router-link>
                      <router-link
                        :to="{ name: 'ProductsListing', params: { type: 'training-aids', view: 'supplies' } }"
                      >
                        <p class="ml-3 mt-1 text-sm text-gray-500 rounded-md hover:bg-gray-100 p-1 px-2">
                          Supplies
                        </p>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="p-5 bg-gray-50 sm:p-8">
                  <a href="#" class="-m-3 p-3 flow-root rounded-md hover:bg-gray-100">
                    <div class="flex items-center">
                      <div class="text-base font-medium text-gray-900">
                        Some New Product To Highlight?
                      </div>
                      <span
                        class="ml-3 inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium leading-5 
                  bg-indigo-100 text-indigo-800"
                      >
                        New
                      </span>
                    </div>
                    <p class="mt-1 text-sm text-gray-500">
                      [New product tagline here]
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </transition>

          <router-link
            :to="{ name: 'About' }"
            class="border-transparent text-gray-500 
             hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
          >
            About
          </router-link>
          <router-link
            :to="{ name: 'Contact' }"
            class="border-transparent text-gray-500 
             hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
          >
            Contact
          </router-link>
          <router-link
            :to="{ name: 'International Distributors' }"
            class="border-transparent text-gray-500 
             hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
          >
            Distributors
          </router-link>
          <router-link
            :to="{ name: 'Newsletter' }"
            class="border-transparent text-gray-500 
             hover:text-gray-700 inline-flex items-center 
            px-1 pt-1 border-b-2 text-xl xl:text-2xl font-normal"
          >
            Newsletter
          </router-link>
        </div>
      </div>
    </transition>
  </nav>
</template>

<style scoped>
.navbar {
  z-index: 1000;
}

.navbar-nav .nav-item {
  font-size: 24px;
}

.btn-outline-secondary {
  background-color: #c0c0c0;
}

.btn-outline-secondary:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #c0c0c0;
}

.dropdown-header {
  font-size: 16px;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  display: block;
  content: ' ';
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

.dropdown-menu-center {
  right: auto;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
</style>

<script>
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
  },
  data: function() {
    return {
      show: false,
      isOpen: false,
      showProductsMenu: false,
      showMobileMenu: false,
    };
  },
  methods: {
    accept(close) {
      close();
    },
  },
  watch: {
    $route() {
      this.showProductsMenu = false;
      this.showMobileMenu = false;
    },
  },
};
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8 bg-white overflow-hidden mb-10">
    <div class="prose prose-indigo prose-lg text-gray-500 max-w-max lg:max-w-5xl mx-auto">
      <!-- <div class="text-2xl">Dummy Components</div> -->
      <p class="mt-5">
        Practical Components offers "factory manufactured" dummy components that are virtually identical to their live
        counterparts. The same manufacturers as the live components specially make them without the expensive live die
        inside, saving you money when only the mechanical characteristics are required.
      </p>

      <p>
        We distribute components for most major manufacturers. Practical is proud to be the exclusive distributor of
        dummies for Amkor Technology. As one of the world's largest providers of contract semiconductor assembly and
        test services, Amkor supplies many of our superior dummy components.
      </p>
    </div>
  </div>

  <ProductGroup
    v-for="group in groups"
    :key="group.groupId"
    :group="group"
    type="dummy-components"
    class="border-t border-gray-200"
  />
</template>

<script>
import ProductService from '@/services/ProductService.js';
import ProductGroup from '@/components/ProductGroup.vue';

export default {
  components: {
    ProductGroup,
  },
  data() {
    return {
      groups: null,
      isOpen: false,
    };
  },
  created() {
    ProductService.getDummyComponents()
      .then(response => {
        this.groups = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    playVideo(event) {
      console.log(event.target);
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

<template>
  <div class="-mx-5 mb-10">
    <img src="@/assets/img/headers/hand-solder-training-kits.jpg" class="w-full" alt="Hand Solder Training Kits" />
  </div>

  <div>
    <SolderTrainingKitsLanding v-if="this.view == ''" />
    <SolderTrainingKitsByName v-if="this.view == 'name'" />
  </div>

  <div>Current View: [{{ this.view }}]</div>
</template>

<script>
import SolderTrainingKitsLanding from '@/components/solder-training-kits/Landing.vue';
import SolderTrainingKitsByName from '@/components/solder-training-kits/ByName.vue';

export default {
  components: {
    SolderTrainingKitsLanding,
    SolderTrainingKitsByName,
  },
  props: {
    view: {
      type: String,
      default: '',
    },
  },
  methods: {
    playVideo(event) {
      console.log(event.target);
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

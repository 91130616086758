<template>
  <metainfo></metainfo>
  <div>
    <TheHeader />
    <main>
      <div class="mx-auto">
        <router-view />
      </div>
    </main>
    <TheFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';

// import axios from 'axios';

export default {
  components: {
    TheHeader,
    TheFooter,
  },
  metaInfo() {
    return {
      title: 'Practical Components',
      meta: [
        {
          name: 'description',
          content: `PCB, Printed Circuit Boards, Dummy Components, Hand Solder Training Kits,
          Evaluation PCB Boards, Training Aids, Tools, Supplies`,
        },
        {
          name: 'keywords',
          content: `PCB, Printed Circuit Boards, Dummy Components, Hand Solder Training Kits,
          Evaluation PCB Boards, Training Aids, Tools, Supplies`,
        },
        {
          name: 'author',
          content: `Practical Components`,
        },
        { property: 'og:title', content: 'Practical Components' },
        { property: 'og:site_name', content: 'www.practicalcomponents.com' },
        { property: 'og:url', content: 'https://www.practicalcomponents.com' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&family=Roboto:wght@100;400&display=swap');

body {
  position: relative;
  font-family: 'Roboto', sans-serif;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

h2 {
  font-family: 'Open Sans', sans-serif;
}
</style>

<template>
  <section class="pt-2" id="qualitypolicy">
    <div class="text-center my-5 text-3xl font-light">
      Quality Policy
    </div>
  </section>

  <div class="max-w-max lg:max-w-7xl mx-auto mb-10 prose prose-indigo prose-lg text-gray-500">
    <p>
      PC and PT are committed to providing quality products and on-time delivery exceeding customer expectations by
      continually improving the QMS.
    </p>
  </div>
</template>

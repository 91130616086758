<template>
  <section class="pt-2" id="privacypolicy">
    <div class="text-center my-5 text-3xl font-light">
      Privacy Policy
    </div>
  </section>

  <div class="max-w-max lg:max-w-7xl mx-auto mb-10 prose prose-indigo prose-lg text-gray-500">
    <div class="font-light text-2xl text-black">
      Practical Components (<a href="http://trustpci.com" target="_blank" style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) is committed to protecting and respecting your privacy.
    </div>

    <p>
      We hope this policy will reassure you that Practical will act sensitively, responsibly and legally with
      information that you provide, and will not sell or market to you unless you provide clear instructions to do so.
    </p>

    <div class="font-light text-2xl text-black">
      What is covered?
    </div>

    <ol>
      <li>Collection of your information</li>
      <li>Information we hold about you</li>
      <li>How we use your information</li>
      <li>Security and protection of your information</li>
      <li>Your rights</li>
      <li>How to change or deactivate your information</li>
      <li>Contact details</li>
    </ol>

    <div class="font-light text-2xl text-black">Collection of Your Information</div>

    <p>
      Practical Components Inc (<a href="http://trustpci.com" target="_blank" style="color:red; font-weight:bold;"
        >trustpci.com</a
      >). is the data controller.
    </p>

    <p>
      We collect information that you share with us through your use of the request a catalog, when you signup for a
      newsletter, request a quotation or make other direct enquiries to us.
    </p>
    <p>
      We may also collect information about your computer, including where available your IP address, operating system
      and browser type, for system administration and to report aggregate information in a summarized form. This is
      statistical data about our user's browsing actions and patterns, and does not directly identify any individual.
    </p>

    <div class="font-light text-2xl text-black">Information we hold about you</div>

    <p>
      Practical Components collects data on it's own behalf. Any information we collect on you is based on your consent.
      We use it to discover your interests, problems and preferences that enables us to enhance the services we provide.
    </p>

    <p>
      In order to participate in orders, catalog requests, newsletter requests or any question that requires an answer
      we require certain personal data. Please, refer to "Use of your information" for more detail. This will generally
      include your name, your email address.
    </p>

    <p>
      We will only ask for information such as postal address when it is necessary as a part of the request that we send
      something to you in the mail.
    </p>

    <p>
      E-mail addresses, from those who communicate with us via e-mail, are used to provide the requested information,
      notify consumers about updates to our Web site and in the case of international customers, shared only with our
      affiliated representatives on request. See below for information on how to be removed from our mailing list.
    </p>

    <div class="font-light text-2xl text-black">Our E-mail, Mailing and Contact Policy</div>

    <p>
      If you do not want to receive future e-mail correspondence from us, please contact us by e-mail (<a
        href="mailto:info@practicalcomponents.com"
        style="color:red; font-weight:bold;"
        >info@practicalcomponents.com</a
      >) or regular mail. Be sure and include your e-mail address and we will promptly remove you from our mailing list.
    </p>

    <p>
      If you supply us with your postal address on-line you may receive periodic mailings from us with information on
      new products and services or upcoming events. If you do not wish to receive such mailings, please provide us with
      your exact name and address as it appears on the mailing label and we will remove you from our mailing list. We
      will also remove your name from any list we may share with representative organizations.
    </p>

    <p>
      Persons who supply us with their telephone numbers on-line may receive telephone contact from us with information
      regarding new products, services or upcoming events. If you do not wish to receive such telephone calls, please
      contact us.
    </p>

    <p>
      With respect to Ad Servers: We do not partner with or have special relationships with any ad server companies.
    </p>

    <p>
      From time to time, we may use customer information for new, unanticipated uses not previously disclosed in our
      privacy policy. If our information practices change in the future, we will only use the information collected for
      these new purposes from the time of the policy change forward.
    </p>

    <p>
      Customers may prevent their information from being used for purposes other than those for which it was originally
      collected by notifying us at any time.
    </p>

    <p>
      We offer visitors the ability to have inaccuracies corrected in contact information, upon request, by sending us
      e-mail at
      <a href="mailto:info@practicalcomponents.com" style="color:red; font-weight:bold;"
        >info@practicalcomponents.com</a
      >
    </p>

    <div class="font-light text-2xl text-black">
      Security
    </div>

    <p>
      We have appropriate security measures in place in our physical facilities to protect against the loss, misuse or
      alteration of information that we have collected from our site.
    </p>

    <div class="font-light text-2xl text-black">
      Use of your information
    </div>

    <p>
      Personal information you have given to us will never be sold or shared with any third party. For legitimate
      business purposes in the case of international customers Practical Components (<a
        href="http://trustpci.com"
        target="_blank"
        style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) may share necessary information with the closest international representative.
    </p>

    <p>
      Practical Components (<a href="http://trustpci.com" target="_blank" style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) remains the sole owner of the information collected. Practical Components (<a
        href="http://trustpci.com"
        target="_blank"
        style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) will only share aggregated information third parties unless we specify otherwise in the terms of a survey or
      site, this is not linked to any personal information that can identify any individual person or organization.
    </p>

    <div class="font-light text-2xl text-black">
      Your Rights
    </div>

    <p>
      You have the right to withdraw from taking part in any mailings, emails or solicitations. You have the right to
      ask us to destroy a record of your engagement with us. We will ensure that your response is not used in our
      analysis provided that we have not already shared it in an aggregated form with third parties
    </p>

    <p>
      You can also unsubscribe/withdraw your consent.
    </p>

    <p>
      You have the right to lodge a complaint with a supervisory authority
    </p>

    <p>
      You can exercise those rights by emailing Practical Components (<a
        href="http://trustpci.com"
        target="_blank"
        style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) at
      <a href="mailto:info@practicalcomponents.com" style="color:red; font-weight:bold;"
        >info@practicalcomponents.com</a
      >
      or by writing to Data Protection, Practical Components Inc. 10762 Noel St. Los Alamitos CA 90720 USA
    </p>

    <div class="font-light text-2xl text-black">Cookies</div>

    <p>
      Practical Components (<a href="http://trustpci.com" target="_blank" style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) does not use cookies.
    </p>

    <p>
      You can configure your browser to notify you when cookies are being placed on your computer. You can also delete
      cookies by adjusting your browser settings.
    </p>

    <div class="font-light text-2xl text-black">Security and Storage of Information Collected</div>

    <p>
      Practical Components (<a href="http://trustpci.com" target="_blank" style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) is committed to maintaining the security of the data we collect and has appropriate security measures in place
      to protect against loss, misuse or alteration of such information. Employees or agents of Practical Components (<a
        href="http://trustpci.com"
        target="_blank"
        style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) can only access data by use of authorized user name, passwords and two-step verification.
    </p>

    <p>
      Practical Components (<a href="http://trustpci.com" target="_blank" style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) will only hold your personally identifiable member data until you request us to delete it. If you decide to
      withdraw your consent to participate, your personally identifiable member data will be deleted immediately. Any
      member data we hold thereafter will be in aggregate format for research purposes ONLY to allow for tracking long
      term trends in consumer behavior. That is, no names, addresses or telephone numbers are attached to any answers,
      but instead answers are provided as counts and percentages.
    </p>

    <p>
      All our online resources are appropriately secured with firewall, packet filtering, port blocking software and
      updated with the latest security patches. Our servers are based in the USA meaning your personal data will never
      leave the USA.
    </p>

    <p>
      Where required we offer SSL (secure sockets layer) (indicated by https:// in the address bar) secured web services
      to offer encrypted web traffic and authentication.
    </p>

    <p>
      Remote administration and data retrieval (e.g. databases) is achieved through encrypted communication with the
      remote computers and means all traffic including passwords and user information is indiscernible if intercepted on
      the way.
    </p>

    <p>
      Practical Components (<a href="http://trustpci.com" target="_blank" style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) follows the requirements of the following laws and codes:
    </p>

    <ol>
      <li>Data Protection Act 1998 (Registration:Z461462X)</li>
      <li>General Data Protection Regulation (GDPR)</li>
      <li>Market Research Society (MRS) Code of Conduct</li>
      <li>ESOMAR (European Society for Opinion and Marketing Research)</li>
      <li>International Code of Marketing and Social Research Practice</li>
    </ol>

    <p>
      If you would like to change or deactivate personal information you have submitted to Practical Components (<a
        href="http://trustpci.com"
        target="_blank"
        style="color:red; font-weight:bold;"
        >trustpci.com</a
      >), you can email us at
      <a href="mailto:info@practicalcomponents.com" style="color:red; font-weight:bold;"
        >info@practicalcomponents.com</a
      >
    </p>

    <p>
      Changes to this Privacy Policy From time to time we may need to update the Practical Components (<a
        href="http://trustpci.com"
        target="_blank"
        style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) Privacy Policy. This Privacy Policy was last amended on 20/05/2018.
    </p>

    <p>
      Sale of Practical or other related organizations: Please note that if any of its lines of business is sold or
      disposed of as a going concern, whether by merger, sale of assets or otherwise, the relevant customer information
      database of could be transferred as part of that transaction and your information accessed by such successor or
      purchaser.
    </p>

    <div class="font-light text-2xl text-black">
      Contact details
    </div>

    <div>
      Practical Components (<a href="http://trustpci.com" target="_blank" style="color:red; font-weight:bold;"
        >trustpci.com</a
      >) appointed Data Protection officer:
    </div>
    <div>Name: Michael Kennedy</div>
    <div>
      Email:
      <a href="mailto:mkennedy@practicalcomponents.com" style="color:red; font-weight:bold;"
        >mkennedy@practicalcomponents.com</a
      >
    </div>
    <div>Tel: +1 714-252-0010</div>
    <div>
      Email:
      <a href="mailto:info@practicalcomponents.com" style="color:red; font-weight:bold;"
        >info@practicalcomponents.com</a
      >
    </div>
  </div>
</template>

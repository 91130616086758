<template>
  <div class="-mx-5 mb-10">
    <img src="@/assets/img/headers/dummy-components.jpg" class="w-full" alt="Dummy Components" />
  </div>

  <div>
    <DummyComponentsLanding v-if="this.view == ''" />
    <DummyComponentsByName v-if="this.view == 'package-name'" />
    <DummyComponentsByPinCount v-if="this.view == 'pin-count'" />
  </div>

  <div>Current View: [{{ this.view }}]</div>
</template>

<script>
import DummyComponentsLanding from '@/components/dummy-components/Landing.vue';
import DummyComponentsByName from '@/components/dummy-components/ByName.vue';
import DummyComponentsByPinCount from '@/components/dummy-components/ByPinCount.vue';

export default {
  components: {
    DummyComponentsLanding,
    DummyComponentsByName,
    DummyComponentsByPinCount,
  },
  props: {
    view: {
      type: String,
      default: '',
    },
  },
  methods: {
    playVideo(event) {
      console.log(event.target);
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

<template>
  <section class="text-gray-600 body-font overflow-hidden">
    <div class="container px-5 py-10 mx-auto divide-y-2 divide-gray-100 space-y-10">
      <div class="bg-white">
        <div class="mx-auto flex flex-wrap">
          <div class="lg:grid lg:grid-cols-2 lg:gap-x-16 lg:items-start">
            <!-- Image gallery -->
            <TabGroup as="div" class="flex flex-col-reverse">
              <!-- Image selector -->
              <div class="mt-6 w-full max-w-2xl mx-auto block lg:max-w-none">
                <TabList class="grid grid-cols-4 gap-6">
                  <Tab
                    v-for="image in product.images"
                    :key="image.id"
                    class="relative h-24 bg-white rounded-md flex items-center justify-center 
                text-sm font-medium uppercase 
                text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none 
                focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                    v-slot="{ selected }"
                  >
                    <span class="absolute inset-0 rounded-md overflow-hidden">
                      <img :src="image.fileLocation" alt="" class="w-full object-center object-cover" />
                    </span>
                    <span
                      :class="[
                        selected ? 'ring-indigo-500' : 'ring-transparent',
                        'absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none',
                      ]"
                      aria-hidden="true"
                    />
                  </Tab>
                </TabList>
              </div>

              <TabPanels class="aspect-w-1 w-full aspect-h-1 border border-gray-200 rounded">
                <TabPanel v-for="image in product.images" :key="image.id" class="flex items-center justify-center p-5">
                  <img :src="image.fileLocation" class="sm:rounded-lg cursor-pointer" @click="showImageModal" />
                </TabPanel>
              </TabPanels>
            </TabGroup>

            <!-- Product info -->
            <div class="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
              <h1 class="text-gray-900 text-3xl title-font font-medium">{{ product.title }}</h1>

              <!-- Reviews -->
              <!-- <div class="mt-3">
                <div class="flex items-center">
                  <div class="flex items-center">
                    <StarIcon
                      v-for="rating in [0, 1, 2, 3, 4]"
                      :key="rating"
                      :class="[4 > rating ? 'text-indigo-500' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']"
                      aria-hidden="true"
                    />
                  </div>
                  <span class="text-gray-600 ml-3">4 Reviews</span>
                </div>
              </div> -->

              <div class="mt-6">
                <h3 class="sr-only">Description</h3>

                <div class="text-gray-700 space-y-6 text-2xl" v-html="product.summary" />
              </div>

              <form class="mt-6" @submit.prevent="addToCart">
                <!-- Colors -->
                <!-- <div>
              <h3 class="text-sm text-gray-600">Color</h3>

              <RadioGroup v-model="selectedColor" class="mt-2">
                <RadioGroupLabel class="sr-only">
                  Choose a color
                </RadioGroupLabel>
                <div class="flex items-center space-x-3">
                  <RadioGroupOption
                    as="template"
                    v-for="color in product.colors"
                    :key="color.name"
                    :value="color"
                    v-slot="{ active, checked }"
                  >
                    <div
                      :class="[
                        color.selectedColor,
                        active && checked ? 'ring ring-offset-1' : '',
                        !active && checked ? 'ring-2' : '',
                        '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center 
                        cursor-pointer focus:outline-none',
                      ]"
                    >
                      <RadioGroupLabel as="p" class="sr-only">
                        {{ color.name }}
                      </RadioGroupLabel>
                      <span
                        aria-hidden="true"
                        :class="[color.bgColor, 'h-8 w-8 border border-black border-opacity-10 rounded-full']"
                      />
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div> -->

                <div v-if="product.isPurchasable" class="mt-10 flex items-center">
                  <span class="title-font font-medium text-2xl text-gray-900">$58.00 ea</span>

                  <!-- <span class="ml-auto title-font font-medium text-xl text-gray-700">Qty: </span> -->
                  <div class="ml-auto flex rounded-md shadow-sm">
                    <span
                      class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 
                      bg-gray-50 text-gray-500 sm:text-sm"
                    >
                      Qty
                    </span>
                    <input
                      type="text"
                      name="quantity"
                      id="quantity"
                      v-model="order.quantity"
                      class="focus:ring-indigo-500 focus:border-indigo-500 
                       rounded-none rounded-r-md border-gray-300 w-20 text-right"
                    />
                  </div>
                  <!-- <select
                    class="ml-5 rounded border appearance-none border-gray-300 py-2 focus:outline-none 
                  focus:ring-2 focus:ring-indigo-200 focus:border-indigo-500 text-base pl-3 pr-10"
                  >
                    <option>1</option>
                    <option>50</option>
                    <option>75</option>
                    <option>100</option>
                  </select> -->
                  <button
                    class="flex ml-5 text-white bg-indigo-600 py-2 px-6  border border-transparent
                          focus:outline-none hover:bg-indigo-700 rounded focus:ring-2 focus:ring-offset-2 
                          focus:ring-offset-gray-50 focus:ring-indigo-500"
                  >
                    Add to cart
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto flex flex-wrap">
        <div class="w-full lg:py-6 mt-6 lg:mt-0">
          <div v-html="product.description"></div>

          <div v-html="product.htmlSupplement"></div>
        </div>
      </div>

      <div class="mx-auto flex flex-wrap">
        <div class="w-full lg:py-6 mt-6 2xl:mt-2">
          <div class="bg-gray-50 overflow-hidden shadow rounded-lg">
            <div class="px-4 py-5 sm:px-6 text-xl">
              Downloads
            </div>
            <div class="bg-white px-4 py-5 sm:p-6">
              <div v-if="product.files.length">
                <div v-for="file in product.files" :key="file.id">
                  <div class="mb-4">
                    <a :href="file.fileLocation" target="_blank" class="text-blue-600 hover:text-blue-500">
                      <font-awesome-icon
                        size="lg"
                        :icon="['fas', returnFileIcon(file.fileLocation)]"
                        class="mr-2 text-gray-500"
                      />
                      {{ file.label }}
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="!product.files.length" class="text-gray-400">
                There are no files available for this product at this time.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <TransitionRoot as="template" :show="showModal">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="showModal = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left 
            overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6
            min-w-0 max-w-4xl"
          >
            <div>
              <div class="mt-3 text-center sm:mt-5">
                <div class="mt-2">
                  <img :src="modalImage" class="inline" />
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6 text-center">
              <button
                type="button"
                class="inline-flex rounded-md border border-transparent 
                shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                @click="showModal = false"
              >
                Close
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
// import { ref } from 'vue';
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/vue';

// import { StarIcon } from '@heroicons/vue/solid';

//DialogTitle,
export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Tab,
    TabGroup,
    TabList,
    TabPanel,
    TabPanels,
    // StarIcon,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data: function() {
    return {
      modalImage: '',
      showModal: false,
      order: {
        id: this.product.id,
        quantity: 1,
      },
    };
  },
  methods: {
    swapImage: function(event) {
      this.initialImage = event.target.src;
      console.log(event.target.src);
      console.log(event);
    },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log('slide change');
    },
    returnFileIcon(fileName) {
      // console.log(fileName);
      const fileExtension = fileName.split('.').pop();
      // console.log(fileExtension);

      switch (fileExtension.trim()) {
        case 'zip':
          return 'file-archive';
        case 'pdf':
          return 'file-pdf';
        default:
          return 'file-alt';
      }
    },
    showImageModal(event) {
      try {
        //this.modalImage = event.currentTarget.style.backgroundImage.split(/"/)[1];
        this.modalImage = event.currentTarget.currentSrc;
        this.showModal = true;
        console.log(event.currentTarget.currentSrc);
        console.log('show image modal click');
      } catch (err) {
        console.log('not image click');
        console.log(event.currentTarget.style.backgroundImage);
      }
    },
    addToCart() {
      console.log(this.order);
    },
  },
};
</script>

<style scoped>
.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}

.vueperslides__bullet--active .default {
  background-color: #42b983;
}

.vueperslides__bullet span {
  display: block;
  color: #fff;
  font-size: 10px;
  opacity: 0.8;
}

.vueperslides--fixed-height.vueperslides--bullets-outside {
  margin-bottom: 0rem;
}

.image-slide {
  cursor: pointer;
}

.vueperslide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-origin: content-box;
  padding: 10px;
}
</style>

<template>
  <section class="pt-2" id="industry-links">
    <div class="text-center my-5 text-3xl font-light">
      Industry Links
    </div>

    <div class="max-w-max lg:max-w-7xl mx-auto mb-10">
      <table class="min-w-full divide-y divide-gray-200">
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="link in links" :key="link.id">
            <td class="px-6 py-4 whitespace-nowrap w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5">
              <div class="flex items-center flex-shrink-0">
                <img class="flex-shrink-0" :src="link.logoFile" alt="" />
              </div>
            </td>
            <td class="px-6 py-4 w-1/2 sm:w-1/2 md:w-2/3 lg:w-4/5">
              <div class="text-md text-gray-900" v-html="link.description"></div>

              <div class="text-md text-gray-900 mt-5">
                <a class="text-indigo-500 hover:text-indigo-600" :href="link.website" target="_blank">{{
                  link.website
                }}</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
import ProductService from '@/services/ProductService.js';

export default {
  data() {
    return {
      links: null,
    };
  },
  created() {
    ProductService.getIndustryLinks()
      .then(response => {
        this.links = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
};
</script>

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './assets/css/main.css';
import 'vue3-carousel/dist/carousel.css';

library.add(fas, far, fab);

const requireComponent = require.context('./components', false, /Base[A-Z]\w+\.(vue|js)$/);
const metaManager = createMetaManager();

const app = createApp(App)
  .use(store)
  .use(router)
  .use(metaManager)
  .use(metaPlugin);

app.component('font-awesome-icon', FontAwesomeIcon);

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName);

  const componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1')));

  app.component(componentName, componentConfig.default || componentConfig);
});

app.config.productionTip = false;

app.mount('#app');

<template>
  <section class="pt-2" id="newsletter">
    <div class="text-center my-5 text-3xl font-light">
      Lead-Free
    </div>

    <div class="max-w-max lg:max-w-7xl mx-auto mb-10" v-for="category in categories" :key="category.categoryLabel">
      <div class="pb-2 mb-2 border-b border-gray-200">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ category.categoryLabel }}
        </h3>
      </div>
      <div v-for="link in category.links" :key="link.id">
        <font-awesome-icon size="sm" :icon="['fas', 'caret-right']" class="mr-2" />
        <a class="text-indigo-500 hover:text-indigo-600" :href="link.file" target="_blank">{{ link.title }}</a>
      </div>
    </div>
  </section>
</template>

<script>
import ProductService from '@/services/ProductService.js';

export default {
  data() {
    return {
      categories: null,
    };
  },
  created() {
    ProductService.getLeadFree()
      .then(response => {
        this.categories = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
};
</script>

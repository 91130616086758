<template>
  <div class="-mx-5 mb-10">
    <img
      src="@/assets/img/headers/pcb-evaluation-boards.jpg"
      class="w-full"
      alt="Process Evaluation PBB Boards and Kits"
    />
  </div>

  <div>
    <PCBBoardsLanding v-if="this.view == ''" />
    <PCBBoardsByName v-if="this.view == 'name'" />
  </div>

  <div>Current View: [{{ this.view }}]</div>
</template>

<script>
import PCBBoardsLanding from '@/components/pcb-boards/Landing.vue';
import PCBBoardsByName from '@/components/pcb-boards/ByName.vue';

export default {
  components: {
    PCBBoardsLanding,
    PCBBoardsByName,
  },
  props: {
    view: {
      type: String,
      default: '',
    },
  },
  methods: {
    playVideo(event) {
      console.log(event.target);
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

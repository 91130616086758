<template>
  <div class="divide-y-2 divide-gray-100">
    <div class="pb-10">
      <div class="py-10 bg-black">
        <div class="bg-black mx-auto relative w-full md:w-5/6 border-2 border-gray-900">
          <div class="aspect-ratio-16/9"></div>
          <video src="@/assets/videos/video.mp4" controls autoplay class="absolute h-full left-0 top-0 w-full"></video>
        </div>
      </div>

      <div class="mt-5 font-light text-2xl text-center">
        Dummy Components - Advanced Test Wafers - Evaluation PCB Boards - Solder Training Kits
      </div>
    </div>

    <section>
      <div class="flex flex-col my-10 space-y-10">
        <img class="object-cover object-center rounded mx-auto" alt="hero" src="@/assets/img/smta-home.png" />

        <img class="object-cover object-center rounded mx-auto" alt="hero" src="@/assets/img/cvbga-home.png" />
      </div>
    </section>

    <section class="text-gray-600 body-font">
      <div class="mx-auto flex px-5 py-20 md:flex-row flex-col items-center bg-gray-100">
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img class="object-cover object-center rounded" alt="hero" src="@/assets/img/smta.png" />
        </div>
        <div
          class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col 
        md:items-start md:text-left items-center text-center"
        >
          <h1 class="text-3xl mb-4 font-normal text-gray-900">
            WTK-1 Terminal and Wire Training Kit
          </h1>
          <p class="mb-8 prose prose-lg max-w-none">
            The WTK-1 Kit includes everything needed to train and practice your wire soldering skills. This kit contains
            three different gauges of wire and five styles of terminals representative of what is available in the
            marketplace.
          </p>
          <div class="flex justify-center space-x-4">
            <button
              class="inline-flex text-gray-700 bg-gray-300 border-0 py-2 
            px-6 focus:outline-none hover:bg-gray-400 rounded text-lg"
            >
              More Details
            </button>
            <button
              class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 
            focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Buy Now
            </button>
          </div>
        </div>
      </div>
    </section>

    <section class="text-gray-600 body-font">
      <div class="mx-auto flex px-5 py-20 md:flex-row flex-col items-center bg-rose-50">
        <div class="md:hidden lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" />
        </div>

        <div
          class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col 
        md:items-start md:text-left items-center text-center"
        >
          <h1 class="text-3xl mb-4 font-normal text-gray-900">
            LCC Leadless Ceramic Carrier
          </h1>
          <p class="mb-8 prose prose-lg max-w-none">
            This surface mount package consists of a ceramic base that has metalized castellations/pads on the sides and
            bottom of the package. LCC packages have pads on all four sides of the package. Lids for LCCs can be either
            metal or ceramic. Lids are attached after die attach. This allows for a hermetically sealed environment for
            the die.
          </p>
          <div class="flex justify-center space-x-4">
            <button
              class="inline-flex text-gray-700 bg-gray-300 border-0 py-2 
            px-6 focus:outline-none hover:bg-gray-400 rounded text-lg"
            >
              More Details
            </button>
            <button
              class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 
            focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Buy Now
            </button>
          </div>
        </div>
        <div class="hidden md:block lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
          <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" />
        </div>
      </div>
    </section>

    <section class="text-gray-600 body-font">
      <div class="px-5 py-20 items-center bg-rose-50">
        <Carousel wrapAround="true" autoplay="8000" transition="2000">
          <Slide v-for="slide in 10" :key="slide">
            <div class="carousel__item">{{ slide }}</div>
          </Slide>

          <template #addons>
            <Navigation />
            <Pagination />
          </template>
        </Carousel>
      </div>
    </section>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style scoped>
.carousel__item {
  min-height: 200px;
  width: 90%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>

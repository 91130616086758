<template>
  <div class="flex flex-col bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
    <div class="bg-gray-50 px-4 py-5 sm:px-6 text-xl lg:text-2xl h-24">
      <router-link
        :to="{
          name: 'ProductDetails',
          params: { id: product.id, type: type, label: product.title },
        }"
      >
        {{ product.title }}
      </router-link>
    </div>
    <div class="flex-1 px-4 py-5 sm:p-6">
      <router-link
        :to="{
          name: 'ProductDetails',
          params: { id: product.id, type: type, label: product.title },
        }"
      >
        <img :src="product.productImage" class="mx-auto mb-5 max-h-200" />
      </router-link>
      <div class="line-clamp-3 prose prose-indigo prose-lg max-w-none">
        {{ product.summary }}
      </div>
    </div>
    <div class="bg-gray-50 px-4 py-4 sm:px-6 text-right">
      <router-link
        :to="{
          name: 'ProductDetails',
          params: { id: product.id, type: type, label: product.title },
        }"
      >
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md 
          text-indigo-700 bg-indigo-100 hover:bg-indigo-200
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          More Details...
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {};
  },
  methods: {},
};
</script>

<template>
  <div class="px-4 sm:px-6 lg:px-8 bg-white overflow-hidden mb-10">
    <div class="prose prose-indigo prose-lg text-gray-500 max-w-max lg:max-w-5xl mx-auto">
      <!-- <div class="text-2xl">Solder Training Kits</div> -->
      <p class="mt-5">
        Practical Components is a leading supplier Hand Solder Training Kits for the electronics industry. From IPC
        globally recognized certification testing to in-house solder training Practical has the perfect kit for your
        program.
      </p>

      <p>
        With kits such as IPC J-Std, IPC/WHMA-A-620C-S Wire Harness or rework kits like PC015 Rev C IPC-7711/7721
        Practical Components has you covered.
      </p>

      <p>
        Need something you don’t, see? Contact us for custom kits.
      </p>
    </div>
  </div>

  <div class="grid grid-cols-1 sm:grid-cols-2 gap-5 border-t border-gray-200 pt-10">
    <ProductCard v-for="product in products" :key="product.id" :product="product" type="solder-training-kits" />
  </div>
</template>

<script>
import ProductService from '@/services/ProductService.js';
import ProductCard from '@/components/ProductCard.vue';

export default {
  components: {
    ProductCard,
  },
  data() {
    return {
      products: null,
    };
  },
  created() {
    ProductService.getSolderKits()
      .then(response => {
        this.products = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    playVideo(event) {
      console.log(event.target);
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

<template>
  <div class="py-2 pb-24" id="contact">
    <div class="text-center my-5 text-3xl font-light">
      Contact Us
    </div>

    <section class="text-gray-600 body-font relative pt-10">
      <div class="container px-5 mx-auto flex sm:flex-nowrap flex-wrap">
        <div
          class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden 
          sm:mr-10 p-10 flex items-end justify-start relative"
        >
          <iframe
            width="100%"
            height="100%"
            class="absolute inset-0"
            frameborder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=10762%20Noel%20St.%20Los%20Alamitos+(Practical%20Components)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
            style="filter: grayscale(1) contrast(1.2) opacity(0.4);"
          ></iframe>
          <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
            <div class="lg:w-1/2 px-6">
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
              <p class="mt-1">10762 Noel St. Los Alamitos<br />CA 90720 USA</p>
            </div>
            <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
              <a class="text-indigo-500 leading-relaxed">info@practicalcomponents.com</a>
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
              <p class="leading-relaxed">1-714-252-0010</p>
            </div>
          </div>
        </div>
        <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-4 md:mt-0">
          <!-- <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Send us a message</h2> -->
          <!-- <p class="leading-relaxed mb-5 text-gray-600">
            Post-ironic portland shabby chic echo park, banjo fashion axe
          </p> -->
          <form @submit.prevent="onSubmit">
            <div class="relative mb-4">
              <label for="company" class="leading-7 text-sm text-gray-600">Company</label>
              <input
                type="text"
                id="company"
                name="company"
                v-model="contact.company"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
              />
            </div>
            <div class="relative mb-4">
              <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                v-model="contact.name"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
              />
            </div>
            <div class="relative mb-4">
              <label for="emailAddress" class="leading-7 text-sm text-gray-600">Email</label>
              <input
                type="email"
                id="emailAddress"
                name="emailAddress"
                v-model="contact.emailAddress"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
              />
            </div>
            <div class="relative mb-4">
              <label for="phoneNumber" class="leading-7 text-sm text-gray-600">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                v-model="contact.phoneNumber"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
              />
            </div>
            <div class="relative mb-4">
              <label for="subject" class="leading-7 text-sm text-gray-600">Subject</label>
              <select
                id="subject"
                name="subject"
                v-model="contact.subject"
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 resize-none 
              leading-6 transition-colors duration-200 ease-in-out"
              >
                <option value="Please have a sales representative contact me.">
                  Please have a sales representative contact me.
                </option>
                <option value="I have a technical question about a product.">
                  I have a technical question about a product.
                </option>
                <option value="I am interested in becoming an international distributor.">
                  I am interested in becoming an international distributor.
                </option>
                <option value="I have an idea for a great new product.">I have an idea for a great new product.</option>
                <option value="I have praise for one of your team.">I have praise for one of your team.</option>
                <option value="I have a problem with my order.">I have a problem with my order.</option>
                <option value="I have an issue with the website.">I have an issue with the website.</option>
                <option value="I'd like to become a supplier to Practical.">
                  I'd like to become a supplier to Practical.
                </option>
              </select>
            </div>

            <div class="relative mb-4">
              <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
              <textarea
                id="message"
                name="message"
                v-model="contact.message"
                required
                class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none 
              leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button
              class="bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center 
              justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-indigo-500"
            >
              Send
            </button>
            <p class="text-xs text-gray-500 mt-3">
              Practical Components will never share your contact information with third parties.
            </p>
          </form>
        </div>
      </div>
    </section>
  </div>

  <MessageComponent
    :title="messageTitle"
    :content="messageContent"
    :display="messageShow"
    :success="messageSuccess"
    @hide="hidePopup"
  />
</template>

<script>
import MessageComponent from '@/components/MessageComponent.vue';
import FormService from '@/services/FormService.js';
import _ from 'underscore';

export default {
  components: {
    MessageComponent,
  },
  data() {
    return {
      contact: {
        company: '',
        name: '',
        emailAddress: '',
        phoneNumber: '',
        subject: 'Please have a sales representative contact me.',
        message: '',
      },
      messageShow: false,
      messageSuccess: true,
      messageTitle: 'Form Sent!',
      messageContent: 'Thank you for submitting your information.',
    };
  },
  methods: {
    onSubmit() {
      FormService.postContact(this.contact)
        .then(() => {
          this.onSuccess();
        })
        .catch(err => {
          this.onError(err);
        });
    },
    hidePopup: function(value) {
      this.messageShow = value;
    },
    onSuccess() {
      this.messageTitle = 'Form Sent!';
      this.messageContent = 'Thank you for submitting your information.';
      this.messageSuccess = true;
      this.messageShow = true;
      this.contact.company = '';
      this.contact.name = '';
      this.contact.emailAddress = '';
      this.contact.phoneNumber = '';
      this.contact.subject = 'Please have a sales representative contact me.';
      this.contact.message = '';
    },
    onError(err) {
      this.messageTitle = 'Whoops!';
      this.messageContent = 'There was an error submitting your information, please try again later.';

      if (err.response) {
        let messages = _.pluck(err.response.data.errors, 'msg');
        this.messageContent = messages.join('<br>');
      }

      this.messageSuccess = false;
      this.messageShow = true;
    },
  },
};
</script>

<template>
  <div class="py-2 pb-24" id="contact">
    <div class="text-center my-5 text-3xl font-light">
      Our Catalog
    </div>

    <section class="text-gray-600 body-font relative pt-10">
      <div class="container px-5 mx-auto flex sm:flex-nowrap flex-wrap">
        <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:m-auto w-full md:py-4 md:mt-0">
          <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Order a free printed catalog</h2>
          <!-- <p class="leading-relaxed mb-5 text-gray-600">
            Post-ironic portland shabby chic echo park, banjo fashion axe
          </p> -->
          <div class="relative mb-4">
            <label for="company" class="leading-7 text-sm text-gray-600">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="company" class="leading-7 text-sm text-gray-600">Job Title</label>
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="company" class="leading-7 text-sm text-gray-600">Company</label>
            <input
              type="text"
              id="company"
              name="company"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="name" class="leading-7 text-sm text-gray-600">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="name" class="leading-7 text-sm text-gray-600">Suite</label>
            <input
              type="text"
              id="suite"
              name="suite"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="name" class="leading-7 text-sm text-gray-600">City</label>
            <input
              type="text"
              id="city"
              name="city"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="name" class="leading-7 text-sm text-gray-600">State</label>
            <input
              type="text"
              id="state"
              name="state"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="name" class="leading-7 text-sm text-gray-600">Zip Code</label>
            <input
              type="text"
              id="zipCode"
              name="zipCode"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="name" class="leading-7 text-sm text-gray-600">Country</label>
            <input
              type="text"
              id="country"
              name="country"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="phone" class="leading-7 text-sm text-gray-600">Phone Number</label>
            <input
              type="text"
              id="phone"
              name="phone"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <div class="relative mb-4">
            <label for="email" class="leading-7 text-sm text-gray-600">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 
              focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 
              transition-colors duration-200 ease-in-out"
            />
          </div>
          <button
            class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            Send
          </button>
          <p class="text-xs text-gray-500 mt-3">
            Practical Components will never share your contact information with third parties.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<template>
  <div class="mx-0 mb-10">
    <img src="@/assets/img/headers/international-distributors.png" class="w-full" />
  </div>
  <section class="pt-2">
    <div class="text-center my-5 text-3xl font-light">
      International Distributors
    </div>

    <div class="flex justify-center">
      <div class="max-w-max lg:max-w-7xl mx-auto px-2 py-5">
        <TabGroup :defaultIndex="0" v-if="distributors">
          <TabList
            class="flex flex-row flex-wrap p-3 space-x-3 justify-center rounded-sm 
            bg-gray-100 border border-gray-300"
          >
            <Tab v-for="region in distributors" as="template" :key="region" v-slot="{ selected }">
              <button
                :class="[
                  'py-2.5 px-5 leading-5 font-medium text-xl text-blue-700 rounded-md',
                  'focus:outline-none focus:ring-1 ring-offset ring-offset-green-500  ring-green-700 ring-opacity-60',
                  selected
                    ? 'bg-gray-200 shadow ring-1 ring-offset ring-offset-green-500  ring-green-700 ring-opacity-60'
                    : 'text-blue-800 hover:bg-white/[0.12] hover:text-blue-500',
                ]"
              >
                {{ region.label }}
              </button>
            </Tab>
          </TabList>

          <TabPanels class="mt-2">
            <TabPanel
              v-for="distributors in distributors"
              :key="distributors"
              :class="['bg-white rounded-xl p-3', 'focus:outline-none']"
            >
              <div v-for="(item, index) in distributors.distributors" :key="item" class="relative p-3 rounded-md">
                <div>
                  <div><img :src="item.photoFile" /></div>
                  <br />
                  <h3 class="text-xl font-medium leading-5">{{ item.title }}</h3>
                  <div class="text-gray-500 text-sm flex flex-wrap flex-row space-x-5">
                    <div v-for="country in item.countryList" :key="country.id">
                      {{ country.country }}
                    </div>
                  </div>
                  <br />

                  <div v-html="item.description"></div>
                  <br />
                  <div>
                    w:
                    <a :href="item.website" target="_blank" class="text-indigo-500 hover:text-indigo-700">{{
                      item.website
                    }}</a>
                  </div>
                  <div>e: {{ item.emailAddress }}</div>
                </div>
                <hr v-if="distributors.distributors.length - 1 !== index" class="my-5" />
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  </section>
</template>

<script>
import ProductService from '@/services/ProductService.js';

import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';

export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
  data() {
    return {
      distributors: null,
    };
  },
  created() {
    ProductService.getDistributors()
      .then(response => {
        this.distributors = response.data.distributors;
      })
      .catch(err => {
        console.log(err);
      });
  },
  setup() {},
  mounted() {},
  updated() {},
};
</script>

<template>
  <div class="py-2 pb-24" id="contact">
    <div class="text-center my-5 text-3xl font-light">
      Map
    </div>

    <section class="text-gray-600 body-font relative pt-10">
      <div class="container px-5 mx-auto">
        <div class="bg-gray-300 rounded-lg overflow-hidden p-10 flex items-end justify-start relative h-96">
          <iframe
            width="100%"
            height="100%"
            class="absolute inset-0"
            frameborder="0"
            title="map"
            marginheight="0"
            marginwidth="0"
            scrolling="no"
            src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=10762%20Noel%20St.%20Los%20Alamitos+(Practical%20Components)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
          ></iframe>
          <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
            <div class="lg:w-1/2 px-6">
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ADDRESS</h2>
              <p class="mt-1">10762 Noel St. Los Alamitos<br />CA 90720 USA</p>
            </div>
            <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">EMAIL</h2>
              <a
                href="mailto:info@practicalcomponents.com"
                class="text-indigo-500 hover:text-indigo-600 leading-relaxed"
                >info@practicalcomponents.com</a
              >
              <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">PHONE</h2>
              <p class="leading-relaxed">1-714-252-0010</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container p-5 mt-5 mx-auto flex sm:flex-nowrap flex-wrap space-x-10">
        <div class="lg:w-1/2 bg-white flex flex-col w-full">
          <h2 class="text-gray-900 text-2xl mb-1 font-medium title-font">Restaurants</h2>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">Katella Deli-Restaurant</div>
              <div>4470 Katella Ave, Los Alamitos, CA</div>
              <div>(562) 594-8611</div>
              <div>
                <a href="https://www.katellabakery.com/" class="text-indigo-500 hover:text-indigo-600" target="_blank"
                  >katellabakery.com</a
                >
              </div>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">El Torito</div>
              <div>5995 Katella Ave, Cypress, CA</div>
              <div>(714) 761-8155</div>
              <div>
                <a href="https://www.eltorito.com/" class="text-indigo-500 hover:text-indigo-600" target="_blank"
                  >eltorito.com</a
                >
              </div>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">Old Ranch Town Center</div>
              <div>12320 Seal Beach Blvd, Seal Beach, CA</div>
              <div>(562) 431-6400</div>
              <div>
                <a
                  href="https://www.islandsrestaurants.com/"
                  class="text-indigo-500 hover:text-indigo-600"
                  target="_blank"
                  >islandsrestaurants.com</a
                >
              </div>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">Red Robin Gourmet Burgers</div>
              <div>5461 Katella Ave, Cypress, CA</div>
              <div>(714) 226-9009</div>
              <div>
                <a href="https://www.redrobin.com/" class="text-indigo-500 hover:text-indigo-600" target="_blank"
                  >redrobin.com</a
                >
              </div>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">In-N-Out Burger</div>
              <div>12320 Seal Beach Blvd, Seal Beach, CA</div>
              <div>(800) 786-1000</div>
              <div>
                <a href="https://www.in-n-out.com/" class="text-indigo-500 hover:text-indigo-600" target="_blank"
                  >in-n-out.com</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="lg:w-1/2 bg-white flex flex-col w-full">
          <h2 class="text-gray-900 text-2xl mb-1 font-medium title-font">Hotels</h2>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">Courtyard Cypress Anaheim/Orange County</div>
              <div>5865 Katella Ave, Cypress, CA</div>
              <div>(714) 827-1010</div>
              <div>
                <a href="https://www.marriott.com/" class="text-indigo-500 hover:text-indigo-600" target="_blank"
                  >marriott.com</a
                >
              </div>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">Residence Inn Cypress Los Alamitos</div>
              <div>4931 Katella Ave, Los Alamitos, CA</div>
              <div>(714) 484-5700</div>
              <div>
                <a href="https://www.marriott.com/" class="text-indigo-500 hover:text-indigo-600" target="_blank"
                  >marriott.com</a
                >
              </div>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">HYATT house Cypress/Anaheim</div>
              <div>5905 Corporate Ave, Anaheim, CA</div>
              <div>(714) 828-4000</div>
              <div>
                <a href="https://www.hyatt.com/" class="text-indigo-500 hover:text-indigo-600" target="_blank"
                  >hyatt.com</a
                >
              </div>
            </div>
          </div>

          <div class="bg-gray-50 overflow-hidden rounded-lg mb-5">
            <div class="px-4 py-5 sm:p-6">
              <div class="mb-1 font-medium text-lg">Best Western Los Alamitos Inn &amp; Suites</div>
              <div>10591 Los Alamitos Blvd, Los Alamitos, CA</div>
              <div>(562) 598-2299</div>
              <div>
                <a href="https://www.bestwestern.com/" class="text-indigo-500 hover:text-indigo-600" target="_blank"
                  >bestwestern.com</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<template>
  <div class="p-5 pt-0">
    <DummyComponents v-if="this.type == 'dummy-components'" v-bind:view="this.view" />
    <PCBBoards v-if="this.type == 'pcb-boards'" v-bind:view="this.view" />
    <SolderTrainingKits v-if="this.type == 'solder-training-kits'" v-bind:view="this.view" />
    <TrainingAids v-if="this.type == 'training-aids'" v-bind:view="this.view" />

    <ProductDaisyChainMatrix v-if="this.type == 'daisy-chain-wafers'" />
  </div>

  <div class="mt-10 pl-5 text-gray-400">Debug</div>
  <div class="pl-5 text-gray-400">Product Group: {{ productType }}</div>
  <div class="pl-5 text-gray-400">Specific View: {{ productView }}</div>
</template>

<script>
import DummyComponents from '@/components/DummyComponents.vue';
import PCBBoards from '@/components/PCBBoards.vue';
import SolderTrainingKits from '@/components/SolderTrainingKits.vue';
import TrainingAids from '@/components/TrainingAids.vue';
import ProductDaisyChainMatrix from '@/components/ProductDaisyChainMatrix.vue';

export default {
  components: {
    DummyComponents,
    PCBBoards,
    SolderTrainingKits,
    TrainingAids,
    ProductDaisyChainMatrix,
  },
  props: {
    type: {
      type: String,
      default: '',
      required: true,
    },
    view: {
      type: String,
      default: '',
      required: false,
    },
  },
  computed: {
    productType() {
      let label = 'Products';

      switch (this.type) {
        case 'dummy-components':
          label = 'Dummy Components';
          break;
        case 'pcb-boards':
          label = 'Process Evaluation PCB Boards and Kits';
          break;
        case 'solder-training-kits':
          label = 'Solder Training Kits';
          break;
        case 'daisy-chain-wafers':
          label = 'Advanced Daisy-Chain Test Die & Wafers';
          break;
        case 'training-aids':
          label = 'Training Aids, Tools & Supplies';
          break;
        default:
          label = 'Products';
          break;
      }

      return label;
    },
    productView() {
      let label = 'Landing Page';

      switch (this.view) {
        case 'package-name':
          label = 'By Package Name';
          break;
        case 'pin-count':
          label = 'By Pin Count';
          break;
        case 'pitch':
          label = 'By Pitch';
          break;
        case 'body-size':
          label = 'By Body Size';
          break;
        case 'name':
          label = 'By Name';
          break;
        case 'cleanliness':
          label = 'Cleanliness & Residue Evaluation + Conformal Coating';
          break;
        case 'solder-print':
          label = 'Solder Print Test';
          break;
        case 'technology':
          label = 'By Technology';
          break;
        case 'ipc-standard-kits':
          label = 'By IPC Standard Kits';
          break;
        case 'through-hole-kits':
          label = 'Through-Hole Kits';
          break;
        case 'wire-terminal-kits':
          label = 'Wire & Terminal Kits';
          break;
        case 'training-aids':
          label = 'Training Aids';
          break;
        case 'tools':
          label = 'Tools';
          break;
        case 'supplies':
          label = 'Supplies';
          break;
        default:
          label = 'Landing Page';
          break;
      }

      return label;
    },
  },
  mounted() {
    console.log(`type: ${this.type} view: ${this.view}`);
  },
};
</script>

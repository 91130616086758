<template>
  <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
    <h2
      class="text-2xl font-bold text-gray-500 leading-7 sm:text-3xl 
      sm:truncate my-10 cursor-pointer hover:text-blue-700"
      @click="isOpen = !isOpen"
    >
      {{ group.groupLabel }}
      <span class="text-sm pl-5 cursor-pointer hover:text-blue-700 text-blue-500" v-if="isOpen">
        collapse <font-awesome-icon size="sm" :icon="['fas', 'caret-up']" />
      </span>
      <span class="text-sm pl-5 cursor-pointer hover:text-blue-700 text-blue-500" v-if="!isOpen">
        expand <font-awesome-icon size="sm" :icon="['fas', 'caret-down']" />
      </span>
    </h2>
    <collapse-transition :duration="300">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-5" v-show="isOpen">
        <ProductCard v-for="product in group.products" :key="product.id" :product="product" :type="type" />
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

export default {
  components: {
    ProductCard,
    CollapseTransition,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: function() {
    return {
      isOpen: false,
      toggleLabel: 'expand +',
    };
  },
  methods: {},
};
</script>

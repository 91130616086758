<template>
  <section class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-wrap w-full mb-20">
        <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
          <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
            Dummy Components
          </h1>
          <div class="h-1 w-20 bg-green-500 rounded"></div>
        </div>
        <p class="lg:w-1/2 w-full leading-relaxed text-gray-500">
          Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table.
          Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid
          celiac humblebrag.
        </p>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-5 -m-4">
        <div class="flex flex-col p-4">
          <div class="bg-gray-100 p-6 rounded-lg flex-1">
            <img src="@/assets/img/43053.png" class="h-40 rounded w-full object-cover object-center mb-6" />
            <h2 class="text-lg text-indigo-500 font-medium title-font mb-4">
              TMV PoP - Through Mold Via Package Dummy Component
            </h2>
            <p class="line-clamp-3 prose prose-indigo prose-md max-w-none">
              After three years of development, Amkor has introduced the next generation PoP solution. This new
              technology is called Through Mold Via (TMV®). The new TMV® technology is used to create interconnect vias
              through the mold cap, it also provides a more stable bottom package that enables the use of thinner
              substrates with a larger die to package ratio. TMV® enabled POP can support single, stacked die for
              wirebond and FC designs.
            </p>
          </div>
        </div>
        <div class="flex flex-col p-4">
          <div class="bg-gray-100 p-6 rounded-lg flex-1">
            <video
              src="@/assets/videos/sample-product-video.mp4"
              class="h-40 rounded w-full object-cover object-center mb-6"
              autoplay
              @mouseenter="playVideo"
              @mousedown="playVideo"
            ></video>
            <h2 class="text-lg text-indigo-500 font-medium title-font mb-4">
              OmQFN Open-molded Quad Flat Pack No Leads
            </h2>
            <p class="line-clamp-3 prose prose-indigo prose-md max-w-none">
              Amkor 0.3mm Dummy CVBGA The Practical Dummy Component® version of the .3mm CVBGA has become a popular
              choice for electronic assembly. It is identical to the live package without the expensive IC die inside.
              The dummy versions are made of the same materials on the same manufacturing lines and have the same size,
              thermal and soldering properties as the live equivalent without the cost of a live die.
            </p>
          </div>
        </div>
        <div class="flex flex-col p-4">
          <div class="bg-gray-100 p-6 rounded-lg flex-1">
            <img src="@/assets/img/2187.png" class="h-40 rounded w-full object-cover object-center mb-6" />
            <h2 class="text-lg text-indigo-500 font-medium title-font mb-4">
              Package on Package (POP, PSVFBGA) Dummy Component-Amkor
            </h2>
            <p class="line-clamp-3 prose prose-indigo prose-md max-w-none">
              Amkor is offering daisy chain samples of their award winning bottom Package Stackable Very Thin Fine Pitch
              BGA (PSvfBGA) and their top PoP optimized for Package on Package (PoP) requirements. PoP has become the
              solution of choice for an increasing number of mobile consumer applications for 3D integration of logic
              and memory devices. Amkor’s PSvfBGA is a high density fine pitch BGA package supporting logic or ASIC
              devices including base band, application and image processors. PoP stacking allows the OEM greater device,
              supplier and time to market flexibility by sourcing the bottom and top devices from their preferred logic
              and memory suppliers and then stacking the devices in the PWB surface mount assembly flow. A wide range of
              leading wireless and mobile integrated device manufacturers are relying on Amkor’s technical and industry
              leadership in PoP.
            </p>
          </div>
        </div>
        <div class="flex flex-col p-4">
          <div class="bg-gray-100 p-6 rounded-lg flex-1">
            <img src="@/assets/img/8-inch-wafer1.jpg" class="h-40 rounded w-full object-cover object-center mb-6" />
            <h2 class="text-lg text-indigo-500 font-medium title-font mb-4">
              .4mm Pitch eWLP Dummy Wafer-Amkor
            </h2>
            <p class="line-clamp-3 prose prose-indigo prose-md max-w-none">
              A-eWLP-Dummy Component Wafer-.4-CuPd-DC-NB 8” wafers embedded copper pads (no balls) daisy-chained 0.4mm
              pitch / 725um thickness. Offered as uncut whole wafers or diced die. Backgrinding available to 200um.
            </p>
          </div>
        </div>
        <div class="flex flex-col p-4">
          <div class="bg-gray-100 p-6 rounded-lg flex-1">
            <img src="@/assets/img/3mm_cvbga.jpg" class="h-40 rounded w-full object-cover object-center mb-6" />
            <h2 class="text-lg text-indigo-500 font-medium title-font mb-4">
              Amkor .3mm CVBGA
            </h2>
            <p class="line-clamp-3 prose prose-indigo prose-md max-w-none">
              Amkor 0.3mm Dummy CVBGA The Practical Dummy Component® version of the .3mm CVBGA has become a popular
              choice for electronic assembly. It is identical to the live package without the expensive IC die inside.
              The dummy versions are made of the same materials on the same manufacturing lines and have the same size,
              thermal and soldering properties as the live equivalent without the cost of a live die.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    playVideo(event) {
      console.log(event.target);
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'http://api.practicalcomponents.com',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  postNewsletter(data) {
    return apiClient.post('/api/form/newsletter', {
      emailAddress: data.email,
    });
  },

  postContact(data) {
    return apiClient.post('/api/form/contact', {
      company: data.company,
      name: data.name,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber,
      subject: data.subject,
      message: data.message,
    });
  },
};

<template>
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="this.display"
          class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 border-2 
          ring-black ring-opacity-5 overflow-hidden"
          :class="this.success ? `border-green-400` : `border-red-400`"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <div v-if="this.success">
                  <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                </div>
                <div v-else>
                  <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
                </div>
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ this.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500" v-html="this.content"></p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="hidePopup"
                  class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/outline';
import { XIcon } from '@heroicons/vue/solid';

export default {
  components: {
    CheckCircleIcon,
    XCircleIcon,
    XIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    display: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      defalse: true,
    },
  },
  methods: {
    hidePopup() {
      this.$emit('hide', false);
    },
  },
};
</script>

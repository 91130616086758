<template>
  <div class="px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
    <div class="prose prose-indigo prose-lg text-gray-500 max-w-max lg:max-w-5xl mx-auto">
      <div class="text-2xl">Training Aids, Tools &amp; Supplies</div>
      <p class="mt-5">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. A cras semper auctor neque vitae. Et malesuada fames ac turpis egestas integer eget aliquet. Vel
        risus commodo viverra maecenas accumsan lacus vel. Sagittis orci a scelerisque purus semper. Tellus elementum
        sagittis vitae et. Sed adipiscing diam donec adipiscing tristique risus nec. Purus in mollis nunc sed id semper
        risus in. Lacus sed turpis tincidunt id aliquet risus feugiat in. Amet luctus venenatis lectus magna fringilla
        urna. Sollicitudin aliquam ultrices sagittis orci a scelerisque purus semper. Accumsan in nisl nisi scelerisque
        eu ultrices vitae auctor. Adipiscing diam donec adipiscing tristique risus nec feugiat. Vitae tortor condimentum
        lacinia quis vel eros donec. Felis bibendum ut tristique et egestas. Sapien et ligula ullamcorper malesuada
        proin libero nunc. Adipiscing elit pellentesque habitant morbi tristique senectus. Dolor sed viverra ipsum nunc.
      </p>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Vitae et leo duis ut diam quam nulla porttitor massa. In cursus turpis massa tincidunt dui. Et
        tortor at risus viverra adipiscing. Pretium nibh ipsum consequat nisl vel pretium lectus. Cursus sit amet dictum
        sit amet justo donec enim. Gravida neque convallis a cras semper. Praesent elementum facilisis leo vel fringilla
        est ullamcorper eget nulla. Ut morbi tincidunt augue interdum velit. Magna ac placerat vestibulum lectus mauris
        ultrices eros in cursus. Posuere lorem ipsum dolor sit amet consectetur adipiscing elit. Gravida quis blandit
        turpis cursus in hac habitasse platea dictumst. Ipsum dolor sit amet consectetur adipiscing elit duis tristique
        sollicitudin. Ac turpis egestas maecenas pharetra.
      </p>
    </div>
  </div>
</template>

/* eslint-disable max-len */
<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <footer class="bg-white border-t" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-none mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-8 xl:col-span-1">
          <div class="flex space-x-10 justify-center items-center">
            <router-link :to="{ name: 'Home' }">
              <img src="@/assets/img/pc-logo.png" class="h-20" alt="Practical Components" />
            </router-link>
            <img src="@/assets/img/iso-logo.png" class="h-28" />
          </div>
          <p class="text-gray-500 text-base text-center">
            Providing quality solutions and continued excellence.
          </p>

          <div class="flex space-x-6 justify-center">
            <div class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Visa</span>
              <font-awesome-icon size="2x" :icon="['fab', 'cc-visa']" />
            </div>

            <div class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Mastercard</span>
              <font-awesome-icon size="2x" :icon="['fab', 'cc-mastercard']" />
            </div>

            <div class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">Amercian Express</span>
              <font-awesome-icon size="2x" :icon="['fab', 'cc-amex']" />
            </div>

            <div class="text-gray-400 hover:text-gray-500">
              <span class="sr-only">PayPal</span>
              <font-awesome-icon size="2x" :icon="['fab', 'cc-paypal']" />
            </div>
          </div>
        </div>
        <div class="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Products
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <router-link
                    :to="{ name: 'Products', params: { type: 'dummy-components' } }"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Dummy Components
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'Products', params: { type: 'pcb-boards' } }"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Process Evaluation PCB Boards &amp; Kits
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'Products', params: { type: 'daisy-chain-wafers' } }"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Advanced Daisy-Chain Test Die &amp; Wafers
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'Products', params: { type: 'solder-training-kits' } }"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Solder Training Kits
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Resources
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <router-link :to="{ name: 'Catalog' }" class="text-base text-gray-500 hover:text-gray-900">
                    Catalog
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Lead-Free' }" class="text-base text-gray-500 hover:text-gray-900">
                    Lead-Free
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Industry Links' }" class="text-base text-gray-500 hover:text-gray-900">
                    Industry Links
                  </router-link>
                </li>

                <li>
                  <router-link
                    :to="{ name: 'International Distributors' }"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    International Distributors
                  </router-link>
                </li>

                <!-- <li>
                  <router-link :to="{ name: 'Site Map' }" class="text-base text-gray-500 hover:text-gray-900">
                    Site Map
                  </router-link>
                </li> -->
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Company
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <router-link :to="{ name: 'About' }" class="text-base text-gray-500 hover:text-gray-900">
                    About
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'Contact' }" class="text-base text-gray-500 hover:text-gray-900">
                    Contact
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Map' }" class="text-base text-gray-500 hover:text-gray-900">
                    Map
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                Legal
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <router-link :to="{ name: 'Privacy Policy' }" class="text-base text-gray-500 hover:text-gray-900">
                    Privacy Policy
                  </router-link>
                </li>

                <li>
                  <router-link :to="{ name: 'Quality Policy' }" class="text-base text-gray-500 hover:text-gray-900">
                    Quality Policy
                  </router-link>
                </li>

                <li>
                  <a
                    href="/pdf/PUR-A8.4-01QualityClauses.pdf"
                    target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Quality Clauses
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="border-t border-gray-200 pt-8 mt-12 lg:flex lg:items-center lg:justify-between">
        <div>
          <h3 class="text-sm font-semibold text-gray-400 tracking-wider uppercase">
            Subscribe to our newsletter
          </h3>
          <p class="mt-2 text-base text-gray-500">
            The latest news, articles, and resources, sent to your inbox weekly.
          </p>
        </div>
        <form class="mt-4 sm:flex sm:max-w-md lg:mt-0" @submit.prevent="onSubmit">
          <label for="emailAddress" class="sr-only">Email address</label>
          <input
            type="email"
            name="emailAddress"
            autocomplete="email"
            v-model="newsletter.email"
            required
            class="appearance-none min-w-0 w-full bg-white border border-gray-300 py-2 px-4 text-base rounded-md 
            text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
            focus:placeholder-gray-400 sm:max-w-xs"
            placeholder="Enter your email"
          />
          <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button
              type="submit"
              class="w-full bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center 
              justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-indigo-500"
            >
              Subscribe
            </button>
          </div>
        </form>
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8">
        <p class="text-base text-gray-400 xl:text-center">
          &copy; 2021 Practical Components Inc.
        </p>
      </div>
    </div>
  </footer>

  <MessageComponent
    :title="messageTitle"
    :content="messageContent"
    :display="messageShow"
    :success="messageSuccess"
    @hide="hidePopup"
  />
</template>

<script>
import MessageComponent from '@/components/MessageComponent.vue';
import FormService from '@/services/FormService.js';
import _ from 'underscore';

export default {
  components: {
    MessageComponent,
  },
  data() {
    return {
      newsletter: {
        email: '',
      },
      messageShow: false,
      messageSuccess: true,
      messageTitle: 'Subscribed Successfully!',
      messageContent: 'Thank you for subscribing to our newsletter.',
    };
  },
  methods: {
    onSubmit() {
      FormService.postNewsletter(this.newsletter)
        .then(() => {
          this.onSuccess();
        })
        .catch(err => {
          this.onError(err);
        });
    },
    hidePopup: function(value) {
      this.messageShow = value;
    },
    onSuccess() {
      this.messageTitle = 'Subscribed Successfully!';
      this.messageContent = 'Thank you for subscribing to our newsletter.';
      this.messageSuccess = true;
      this.messageShow = true;
      this.newsletter.email = '';
    },
    onError(err) {
      this.messageTitle = 'Whoops!';
      this.messageContent = 'There was an error submitting your information, please try again later.';

      if (err.response) {
        let messages = _.pluck(err.response.data.errors, 'msg');
        this.messageContent = messages.join('<br>');
      }

      this.messageSuccess = false;
      this.messageShow = true;
    },
  },
};
</script>

<template>
  <ProductGroup v-for="group in groups" :key="group.groupId" :group="group" type="dummy-components" />
</template>

<script>
import ProductService from '@/services/ProductService.js';
import ProductGroup from '@/components/ProductGroup.vue';

export default {
  components: {
    ProductGroup,
  },
  data() {
    return {
      groups: null,
      isOpen: false,
    };
  },
  created() {
    ProductService.getDummyComponents()
      .then(response => {
        this.groups = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },

  methods: {
    playVideo(event) {
      console.log(event.target);
      console.log('play video hover');
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
    playVideoClick(event) {
      console.log(event.target);
      console.log('play video click');
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

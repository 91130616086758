<template>
  <div class="px-4 sm:px-6 lg:px-8 bg-white overflow-hidden mb-10">
    <div class="prose prose-indigo prose-lg text-gray-500 max-w-max lg:max-w-5xl mx-auto">
      <!-- <div class="text-2xl">PCB Boards &amp; Kits</div> -->
      <p class="mt-5">
        Practical supplies a large variety of machine capability and process evaluation boards and kits.
      </p>

      <p>
        If you need to check or qualify your process cleanliness, conformal coating testing, solder paste evaluation,
        Sir testing or many others Practical has the boards and needed components for you.
      </p>

      <p>
        Need something you don't see? Contact us for custom boards/kits.
      </p>
    </div>
  </div>

  <ProductGroup
    v-for="group in groups"
    :key="group.groupId"
    :group="group"
    type="pcb-boards"
    class="border-t border-gray-200"
  />
</template>

<script>
import ProductService from '@/services/ProductService.js';
import ProductGroup from '@/components/ProductGroup.vue';

export default {
  components: {
    ProductGroup,
  },
  data() {
    return {
      groups: null,
      isOpen: false,
    };
  },
  created() {
    ProductService.getPCBBoards()
      .then(response => {
        this.groups = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
    playVideo(event) {
      console.log(event.target);
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-5">
    <ProductCard v-for="product in products" :key="product.id" :product="product" type="training-aids" />
  </div>
</template>

<script>
import ProductService from '@/services/ProductService.js';
import ProductCard from '@/components/ProductCard.vue';

export default {
  components: {
    ProductCard,
  },
  data() {
    return {
      products: null,
    };
  },
  created() {
    ProductService.getTrainingAids()
      .then(response => {
        this.products = response.data;
      })
      .catch(err => {
        console.log(err);
      });
  },

  methods: {
    playVideo(event) {
      console.log(event.target);
      console.log('play video hover');
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
    playVideoClick(event) {
      console.log(event.target);
      console.log('play video click');
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

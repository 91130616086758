<template>
  <div class="mx-0 mb-10">
    <img src="@/assets/img/about-building.png" class="w-full" />
  </div>
  <div class="my-5 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
    <div class="max-w-max lg:max-w-7xl mx-auto">
      <div class="relative z-10 mb-8 md:mb-2 md:px-6">
        <div class="text-base max-w-prose lg:max-w-none">
          <h2 class="text-3xl font-light text-center">
            About Practical Components
          </h2>
        </div>
      </div>
      <div class="relative">
        <div class="relative md:bg-white md:p-6">
          <div class="lg:grid lg:grid-cols-2 lg:gap-20">
            <div>
              <div class="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                <p>
                  At Practical Components, we want to solve the biggest problems in electronics:
                  <strong>How to improve assembly quality and smoothly transition to new technology?</strong>
                </p>
                <p>
                  Practical Components has supplied millions of dummy components, advanced test wafers, evaluation PCB
                  boards and solder training kits to companies and research centers worldwide since 1996.
                </p>
                <p>
                  Our customer base includes most major OEM, EMS, aerospace, automotive, government, medical,
                  universities and research sectors.
                </p>
                <p>
                  Our products solve problems, certify processes, introduce new concepts and help identify opportunities
                  for production improvement.
                </p>
                <p>
                  What makes Practical Components stand apart?
                </p>
                <ul>
                  <li># 1 in on-time delivery.</li>
                  <li># 1 with in-stock products.</li>
                  <li># 1 in advanced test products</li>
                </ul>

                <!-- <img src="@/assets/img/about-building.jpg" class="rounded-lg border-2 border-gray-400" /> -->
              </div>
              <div class="mt-9 inline-flex rounded-md shadow">
                <router-link
                  class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium 
              rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                  :to="{ name: 'Contact' }"
                >
                  Contact Sales
                </router-link>
              </div>
            </div>
            <div>
              <div class="mt-6 prose prose-indigo prose-lg text-gray-500">
                <p>
                  Most importantly: We are # 1 in both in-house and manufacturer support.
                </p>

                <p>You have questions, our team has the answers!</p>

                <p>
                  Who we are: Practical Components is an international supplier of products for the electronics
                  production chain. Our products are used from research to the production floor.
                </p>

                <p>
                  Our Practical Dummy Components save time, money and improve production.
                </p>

                <p>
                  Our Advanced Dummy Wafer products offer the ability to research and experiment with new technology and
                  or technology new to your needs.
                </p>

                <p>
                  The Practical team, from beginning to end, endeavors to make a customer-centric interaction.
                </p>
                <p>
                  Founded in Los Alamitos California in 1996, Practical Components leads the industry with expertise and
                  inventiveness.
                </p>

                <p>
                  We continuously seek to improve our operating performance. As an ISO 9000 2015 certified company we
                  utilize quality management standards intended to increase both business efficiency and customer
                  satisfaction.
                </p>
                <p>
                  Practical’s reach is strengthened by a network of authorized distributors worldwide. Here.
                </p>
              </div>
            </div>
          </div>

          <div class="lg:grid lg:grid-cols-1 py-10 border-blue-600 px-10">
            <div>
              <div class="bg-black mx-auto relative w-full md:w-full border-2 border-gray-200">
                <div class="aspect-ratio-16/9"></div>
                <video
                  src="@/assets/videos/why-use-dummy-components.mp4"
                  controls
                  class="absolute h-full left-0 top-0 w-full"
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'http://api.practicalcomponents.com',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  getDummyComponents() {
    return apiClient.get('/api/dummy-components');
  },

  getDummyComponent(id) {
    return apiClient.get(`/api/dummy-components/${id}`);
  },

  getSolderKits() {
    return apiClient.get('/api/solder-kits');
  },

  getSolderKit(id) {
    return apiClient.get(`/api/solder-kits/${id}`);
  },

  getPCBBoards() {
    return apiClient.get('/api/pcb-boards');
  },

  getPCBBoard(id) {
    return apiClient.get(`/api/pcb-boards/${id}`);
  },

  getLeadFree() {
    return apiClient.get('/api/lead-free');
  },

  getIndustryLinks() {
    return apiClient.get('/api/industry-links');
  },

  getTools() {
    return apiClient.get('/api/tools');
  },

  getTool(id) {
    return apiClient.get(`/api/tools/${id}`);
  },

  getSupplies() {
    return apiClient.get('/api/supplies');
  },

  getSupply(id) {
    return apiClient.get(`/api/supplies/${id}`);
  },

  getTrainingAids() {
    return apiClient.get('/api/training-aids');
  },

  getTrainingAid(id) {
    return apiClient.get(`/api/training-aids/${id}`);
  },

  getTestWafers() {
    return apiClient.get('/api/test-wafers');
  },

  getTestWafer(id) {
    return apiClient.get(`/api/test-wafers/${id}`);
  },

  getDistributors() {
    return apiClient.get('/api/distributors');
  },
};

<template>
  <div class="-mx-5">
    <img src="@/assets/img/headers/daisy-chain-wafers.jpg" class="w-full" alt="Daisy-Chain Test Die and Wafers" />
  </div>
  <div class="pt-10 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
    <div class="prose prose-indigo prose-lg text-gray-500 max-w-max lg:max-w-5xl mx-auto">
      <p class="mt-5">
        Practical Components is the world's premier supplier of Advanced Daisy-Chain Test Die & Wafers.
      </p>

      <p>
        We can supply a vast number of test die with Au plate or studs, solderNi+ plate or mount, Cu Plate, Cu + SnAg
        paste, Cu+Ni+SnAg plate, Cu+NiAu, Ni Plate, Ni+SNAg plate or Electroless Ni/Au plate.
      </p>

      <p>
        Pad pitch as small as 40μm and a variety of chip sizes available.
      </p>
    </div>
  </div>

  <div class="my-20 mx-auto">
    <h2 class="text-3xl mb-4 font-normal">KIT LINE UP</h2>
    <div class="p-3">
      <table class="w-full border-collapse text-sm border" cellpadding="4">
        <tbody>
          <tr>
            <th valign="middle" width="100" class="text-center border">
              Material
            </th>
            <th class="text-center border">
              Ni/Au
            </th>
            <th class="text-center border">
              Cu
            </th>
            <th class="text-center border">
              Cu +OSP
            </th>
            <th class="text-center border">
              <div>E-679FG</div>
              <div>SAC</div>
            </th>
            <th class="border">
              <div>E-700G</div>
              <div>SAC</div>
            </th>
            <th class="border">
              <div>E-705G</div>
              <div>SAC</div>
            </th>
            <th class="border">
              <div>E-700G</div>
              <div>NiPdAu + SAC</div>
            </th>
            <th class="border">
              <div>E-705G</div>
              <div>NiPdAu + SAC</div>
            </th>
            <th class="border">
              NiPdAu
            </th>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 49, type: 'test-wafers', label: 'CC80(T)-0106JY' },
                }"
              >
                CC80
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 51, type: 'test-wafers', label: 'FC150LC(S)-0303JY' },
                }"
              >
                FC150LC-1 X 1
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 52, type: 'test-wafers', label: 'FC120' },
                }"
              >
                FC120-1 X 1
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 52, type: 'test-wafers', label: 'FC120' },
                }"
              >
                FC120-2 X 2
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 53, type: 'test-wafers', label: 'FC150' },
                }"
              >
                FC150-1 X 1
              </router-link>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 53, type: 'test-wafers', label: 'FC150' },
                }"
              >
                FC150-2 X 2
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 54, type: 'test-wafers', label: 'FC200' },
                }"
              >
                FC200-1 X 1
              </router-link>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 54, type: 'test-wafers', label: 'FC200' },
                }"
              >
                FC200-2 X 2
              </router-link>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 55, type: 'test-wafers', label: 'WLP(S)300P/400P' },
                }"
              >
                WLP300P/400P
              </router-link>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 56, type: 'test-wafers', label: 'STAC(S)-0202JY' },
                }"
              >
                STAC
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <hr class="m-10" />

    <div class="p-3">
      <table class="w-full border-collapse text-sm border" cellpadding="4">
        <tbody>
          <tr>
            <th valign="middle" rowspan="2" width="100" class="text-center border">
              Material
            </th>
            <th class="text-center border" colspan="2" rowspan="2">
              <strong>Au</strong>
            </th>
            <th class="text-center border border-r-4" colspan="2" rowspan="2">
              <strong>Solder</strong>
            </th>
            <th class="text-center border" colspan="3" valign="bottom">
              <strong>Cu</strong>
            </th>
            <th class="text-center border border-r-4" colspan="2" valign="bottom">
              <strong>Ni</strong>
            </th>
            <th rowspan="2" class="border" style="font-size : 1em;text-align : center;">
              <div style="font-size : 85%;">Electroless</div>
              Ni/Au
            </th>
          </tr>
          <tr>
            <th class="text-center border" valign="bottom"><strong>-</strong></th>
            <th valign="bottom" class="border"><strong>+SnAg</strong></th>
            <th valign="bottom" class="border"><strong>+Ni+SnAg</strong></th>

            <th valign="bottom" class="border"><strong>-</strong></th>
            <th valign="bottom" class="border border-r-4"><strong>+SnAg</strong></th>
          </tr>
          <tr>
            <td class="border border-b-2"></td>
            <td class="text-center border border-b-2">Plate</td>
            <td class="text-center border border-b-2">Stud</td>
            <td class="text-center border border-b-2">Plate</td>
            <td class="text-center border border-b-2 border-r-4">Mount</td>
            <td class="text-center border border-b-2">Plate</td>
            <td class="text-center border border-b-2">Plate</td>
            <td class="text-center border border-b-2">Plate</td>

            <td class="text-center border border-b-2">Plate</td>
            <td class="text-center border border-b-2 border-r-4">Plate</td>
            <td class="text-center border border-b-2">Plate</td>
          </tr>
          <tr class="ui-gray-bg">
            <td class="border bg-gray-50"></td>
            <td class="border bg-gray-50">
              <img src="https://www.practicalcomponents.com/assets/img/twp/plate-au.png" width="50" class="m-auto" />
            </td>
            <td class="border bg-gray-50">
              <img src="https://www.practicalcomponents.com/assets/img/twp/stud-au.png" width="50" class="m-auto" />
            </td>
            <td class="border bg-gray-50">
              <img src="https://www.practicalcomponents.com/assets/img/twp/plate-snag.png" width="50" class="m-auto" />
            </td>
            <td class="border bg-gray-50 border-r-4">
              <img src="https://www.practicalcomponents.com/assets/img/twp/mount-sac.png" width="50" class="m-auto" />
            </td>
            <td class="border bg-gray-50">
              <img src="https://www.practicalcomponents.com/assets/img/twp/plate-cu.png" width="50" class="m-auto" />
            </td>
            <td class="border bg-gray-50">
              <img
                src="https://www.practicalcomponents.com/assets/img/twp/plate-snagcu.png"
                width="50"
                class="m-auto"
              />
            </td>
            <td class="border bg-gray-50">
              <img
                src="https://www.practicalcomponents.com/assets/img/twp/plate-nisnagcu.png"
                width="50"
                class="m-auto"
              />
            </td>
            <td class="border bg-gray-50">
              <img src="https://www.practicalcomponents.com/assets/img/twp/plate-ni.png" width="50" class="m-auto" />
            </td>
            <td class="border bg-gray-50 border-r-4">
              <img
                src="https://www.practicalcomponents.com/assets/img/twp/plate-snagni.png"
                width="50"
                class="m-auto"
              />
            </td>
            <td class="border bg-gray-50">
              <img src="https://www.practicalcomponents.com/assets/img/twp/plate-niau.png" width="50" class="m-auto" />
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 1, type: 'test-wafers', label: 'MB50-0101JY' },
                }"
              >
                MB50
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 2, type: 'test-wafers', label: 'MB80-STG0101JY' },
                }"
              >
                MB80
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 39, type: 'test-wafers', label: 'WALTS-TEG AS8R' },
                }"
              >
                AS8R
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 3, type: 'test-wafers', label: 'Practical Components Test Wafer MB130-STG0101JY' },
                }"
              >
                MB130A / MB130B
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 34, type: 'test-wafers', label: 'MB6020' },
                }"
              >
                MB6020
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 4, type: 'test-wafers', label: 'CC40-0101JY' },
                }"
              >
                CC40
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 6, type: 'test-wafers', label: 'IP40-0101JY & IP40A-0101JY' },
                }"
                >IP40
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 6, type: 'test-wafers', label: 'IP40-0101JY & IP40A-0101JY' },
                }"
              >
                IP40A
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 7, type: 'test-wafers', label: 'CC80-0101JY' },
                }"
              >
                CC80
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 9, type: 'test-wafers', label: 'IP80-0101JY' },
                }"
              >
                IP80
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 8, type: 'test-wafers', label: 'CC80TSV-0101JY' },
                }"
              >
                CC80TSV
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'square']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 36, type: 'test-wafers', label: 'CC80MarkIV-0101JY (A/B)' },
                }"
              >
                CC80MarkIV
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 35, type: 'test-wafers', label: 'IP80 MarkIV-0101JY' },
                }"
              >
                IP80MarkIV
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 40, type: 'test-wafers', label: 'WALTS-TEG WM40-0101JY' },
                }"
              >
                WM40-0101
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'square']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 41, type: 'test-wafers', label: 'WALTS-TEG WM40-0102JY' },
                }"
              >
                WM40-0102
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 42, type: 'test-wafers', label: 'WALTS-TEG WM40-0103JY' },
                }"
              >
                WM40-0103
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 38, type: 'test-wafers', label: 'IPWM40' },
                }"
              >
                IPWM40
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 43, type: 'test-wafers', label: 'WALTS-TEG HBM_T-0100JY' },
                }"
              >
                HBM-T
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 44, type: 'test-wafers', label: 'WALTS-TEG IPHBM-0100JY' },
                }"
              >
                IPHBM
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 12, type: 'test-wafers', label: 'FC150LC-0101JY' },
                }"
              >
                FC150LC
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 31, type: 'test-wafers', label: 'KIT FC120' },
                }"
              >
                FC120
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 11, type: 'test-wafers', label: 'FC150JY' },
                }"
              >
                FC150
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 11, type: 'test-wafers', label: 'FC150JY' },
                }"
              >
                FC150SC
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 14, type: 'test-wafers', label: 'FC200JY' },
                }"
              >
                FC200
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 14, type: 'test-wafers', label: 'FC200JY' },
                }"
              >
                FC200SC
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 16, type: 'test-wafers', label: 'FBW' },
                }"
              >
                FBW
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 17, type: 'test-wafers', label: 'WLP TEG (0.4mm pitch & 0.3mm pitch)' },
                }"
              >
                WLP
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: {
                    id: 18,
                    type: 'test-wafers',
                    label: 'WLP TEG (Free Size Cut TEG: TEG0306, TEG0408, TEG0510)',
                  },
                }"
              >
                Free Cut Size
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 19, type: 'test-wafers', label: 'ME0102JY (for Migration Test)' },
                }"
              >
                ME
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 20, type: 'test-wafers', label: 'STAC-0101JY' },
                }"
              >
                STAC
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 21, type: 'test-wafers', label: 'STACTEG-150FA-0101JY & STACTEG-300FA-0101JY' },
                }"
              >
                STAC150FA/300FA
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 23, type: 'test-wafers', label: 'HPW-0101JY' },
                }"
              >
                HPW
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 24, type: 'test-wafers', label: 'HPWTEG-150FA-0101JY & HPWTEG-300FA-0101JY' },
                }"
              >
                HPW150FA/300FA
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 25, type: 'test-wafers', label: 'HPWTSV-0101JY' },
                }"
              >
                HPWTSV
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'square']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 45, type: 'test-wafers', label: 'WALTS-TEG HPW MarkII-0101JY' },
                }"
              >
                HPW Mark II
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>

          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 57, type: 'test-wafers', label: 'LCD30-0101JY' },
                }"
              >
                LCD30A
              </router-link>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 22, type: 'test-wafers', label: 'PWB0101JY' },
                }"
              >
                PWB8
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 46, type: 'test-wafers', label: 'SIPOS-TEG SI0631' },
                }"
              >
                SI0631
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 47, type: 'test-wafers', label: 'ITO1101' },
                }"
              >
                ITO1101
              </router-link>
              /
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 48, type: 'test-wafers', label: 'ITO1102' },
                }"
              >
                ITO1102
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
          <!--  -->
          <!---
          <tr>
            <td class="text-center font-bold border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 37, type: 'test-wafers', label: 'Full Bump Wafer FBW500' },
                }"
              >
                [[REMOVE PAST HERE]]
              </router-link>
            </td>
            <td class="text-center border">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border border-r-4">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border border-r-4">
              [[REMOVE PAST HERE]]
            </td>
            <td class="text-center border">
              [[REMOVE PAST HERE]]
            </td>
          </tr>
          <tr>
            <td class="text-center font-bold border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 37, type: 'test-wafers', label: 'Full Bump Wafer FBW500' },
                }"
              >
                FBW500
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>

          <tr>
            <td class="text-center font-bold">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 29, type: 'test-wafers', label: 'TEG WM40-0103JY' },
                }"
              >
                WM40-0103JY
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center font-bold">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 30, type: 'test-wafers', label: 'TEG FC120JY' },
                }"
              >
                FC120JY
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>

          <tr>
            <td class="text-center font-bold">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: {
                    id: 32,
                    type: 'test-wafers',
                    label: 'WLP TEG (Free Size Cut: TEG0306, TEG03507, TEG0408, TEG0510)',
                  },
                }"
              >
                WLP TEG Free Cut
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>
          <tr>
            <td class="text-center font-bold">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 33, type: 'test-wafers', label: 'SIPOS-TEG SI06' },
                }"
              >
                TEG SI06
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>

          <tr>
            <td class="text-center font-bold">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 5, type: 'test-wafers', label: 'MB60-0101JY' },
                }"
              >
                MB60
              </router-link>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>

          <tr>
            <td class="text-center font-bold">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 10, type: 'test-wafers', label: 'CC80 MarkII-0101JY (STD)' },
                }"
              >
                CC80Mark II
              </router-link>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>

          <tr>
            <td class="text-center font-bold">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 13, type: 'test-wafers', label: 'FC150SCJY' },
                }"
              >
                FC150SC
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>

          <tr>
            <td class="text-center font-bold">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 15, type: 'test-wafers', label: 'FC200SCJY' },
                }"
              >
                FC200SC
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
          </tr>

          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 21, type: 'test-wafers', label: 'STACTEG-150FA-0101JY & STACTEG-300FA-0101JY' },
                }"
              >
                STAC300FA
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr>

          <tr>
            <td class="text-center border">
              <router-link
                class="text-blue-500 hover:text-blue-700"
                :to="{
                  name: 'ProductDetails',
                  params: { id: 24, type: 'test-wafers', label: 'HPWTEG-150FA-0101JY & HPWTEG-300FA-0101JY' },
                }"
              >
                HPW300FA
              </router-link>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
            <td class="text-center border border-r-4">
              <em>-</em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border border-r-4">
              <em><font-awesome-icon size="sm" :icon="['fas', 'circle']"/></em>
            </td>
            <td class="text-center border">
              <em>-</em>
            </td>
          </tr> 
          --->
        </tbody>
      </table>
    </div>
    <div class="text-right mt-3 text-xs">
      <font-awesome-icon size="sm" :icon="['fas', 'circle']" /> Top Side /
      <font-awesome-icon size="sm" :icon="['far', 'circle']" /> Bottom Side<br />
    </div>
  </div>
</template>

import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Maps from '../views/Map.vue';
import Newsletter from '../views/Newsletter.vue';
import EventDetails from '../views/EventDetails.vue';
import Contact from '../views/Contact.vue';
import Catalog from '../views/Catalog.vue';
import Products from '../views/Products.vue';
import ProductDetails from '../views/ProductDetails.vue';
import NotFound from '../views/NotFound.vue';
import LeadFree from '../views/LeadFree.vue';
import IndustryLinks from '../views/IndustryLinks.vue';
import InternationalDistributors from '../views/InternationalDistributors.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import QualityPolicy from '../views/QualityPolicy.vue';
import QualityClauses from '../views/QualityClauses.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: function() {
    //   return import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
  },
  {
    path: '/event/:id',
    name: 'EventDetails',
    props: true,
    component: EventDetails,
  },
  {
    path: '/contact',
    name: 'Contact',
    props: true,
    component: Contact,
  },
  {
    path: '/map',
    name: 'Map',
    props: true,
    component: Maps,
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: Newsletter,
  },
  {
    path: '/catalog',
    name: 'Catalog',
    props: true,
    component: Catalog,
  },
  {
    path: '/lead-free',
    name: 'Lead-Free',
    props: true,
    component: LeadFree,
  },
  {
    path: '/industry-links',
    name: 'Industry Links',
    props: true,
    component: IndustryLinks,
  },
  {
    path: '/international-distributors',
    name: 'International Distributors',
    props: true,
    component: InternationalDistributors,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    props: true,
    component: PrivacyPolicy,
  },
  {
    path: '/quality-policy',
    name: 'Quality Policy',
    props: true,
    component: QualityPolicy,
  },
  {
    path: '/quality-clauses',
    name: 'Quality Clauses',
    props: true,
    component: QualityClauses,
  },
  {
    path: '/products/:type',
    name: 'Products',
    props: true,
    component: Products,
  },
  {
    path: '/products/:type/:view',
    name: 'ProductsListing',
    props: true,
    component: Products,
  },
  {
    path: '/products/:type/:id/:label',
    name: 'ProductDetails',
    props: true,
    component: ProductDetails,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<template>
  <section class="pt-2" id="newsletter">
    <div class="text-center my-5 text-3xl font-light">
      Newsletter
    </div>
  </section>

  <div class="max-w-max lg:max-w-7xl mx-auto prose text-center">
    <p>The latest news, articles, and resources, sent to your inbox weekly.</p>

    <form class="mt-4 flex pb-10 justify-center" @submit.prevent="onSubmit">
      <label for="emailAddress" class="sr-only">Email address</label>
      <input
        type="email"
        name="emailAddress"
        autocomplete="email"
        v-model="newsletter.email"
        required
        class="appearance-none min-w-0 w-full bg-white border border-gray-300 py-2 px-4 text-base rounded-md 
            text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 
            focus:placeholder-gray-400 sm:max-w-xs"
        placeholder="Enter your email"
      />
      <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button
          type="submit"
          class="w-full bg-indigo-600 border border-transparent rounded-md py-2 px-4 flex items-center 
              justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-indigo-500"
        >
          Subscribe
        </button>
      </div>
    </form>
  </div>

  <MessageComponent
    :title="messageTitle"
    :content="messageContent"
    :display="messageShow"
    :success="messageSuccess"
    @hide="hidePopup"
  />
</template>

<script>
import MessageComponent from '@/components/MessageComponent.vue';
import FormService from '@/services/FormService.js';
import _ from 'underscore';

export default {
  components: {
    MessageComponent,
  },
  data() {
    return {
      newsletter: {
        email: '',
      },
      messageShow: false,
      messageSuccess: true,
      messageTitle: 'Subscribed Successfully!',
      messageContent: 'Thank you for subscribing to our newsletter.',
    };
  },
  methods: {
    onSubmit() {
      FormService.postNewsletter(this.newsletter)
        .then(() => {
          this.onSuccess();
        })
        .catch(err => {
          this.onError(err);
        });
    },
    hidePopup: function(value) {
      this.messageShow = value;
    },
    onSuccess() {
      this.messageTitle = 'Subscribed Successfully!';
      this.messageContent = 'Thank you for subscribing to our newsletter.';
      this.messageSuccess = true;
      this.messageShow = true;
      this.newsletter.email = '';
    },
    onError(err) {
      this.messageTitle = 'Whoops!';
      this.messageContent = 'There was an error submitting your information, please try again later.';

      if (err.response) {
        let messages = _.pluck(err.response.data.errors, 'msg');
        this.messageContent = messages.join('<br>');
      }

      this.messageSuccess = false;
      this.messageShow = true;
    },
  },
};
</script>

<style scoped></style>

<template>
  <div v-if="product">
    <div class="p-5">
      <ProductDetail :product="product" :type="type" />
    </div>
  </div>
</template>

<script>
import ProductService from '@/services/ProductService.js';
import ProductDetail from '@/components/ProductDetail.vue';

export default {
  components: {
    ProductDetail,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    productType() {
      let label = 'Products';

      switch (this.type) {
        case 'dummy-components':
          label = 'Dummy Components';
          break;
        case 'pcb-boards':
          label = 'Process Evaluation PCB Boards and Kits';
          break;
        case 'solder-training-kits':
          label = 'Solder Training Kits';
          break;
        case 'daisy-chain-wafers':
          label = 'Advanced Daisy-Chain Test Die & Wafers';
          break;
        default:
          label = 'Products';
          break;
      }

      return label;
    },
    productId() {
      return this.id;
    },
    productLabel() {
      return this.label;
    },
  },
  mounted() {
    console.log(`type: ${this.type} id: ${this.id} label: ${this.label}`);
  },
  data() {
    return {
      product: null,
    };
  },
  created() {
    if (this.type === 'solder-training-kits') {
      ProductService.getSolderKit(this.id)
        .then(response => {
          this.product = response.data[0];
          console.log(this.product);
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.type === 'dummy-components') {
      ProductService.getDummyComponent(this.id)
        .then(response => {
          this.product = response.data[0];
          console.log(this.product);
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.type === 'pcb-boards') {
      ProductService.getPCBBoard(this.id)
        .then(response => {
          this.product = response.data[0];
          console.log(this.product);
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.type === 'tools') {
      ProductService.getTool(this.id)
        .then(response => {
          this.product = response.data[0];
          console.log(this.product);
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.type === 'supplies') {
      ProductService.getSupply(this.id)
        .then(response => {
          this.product = response.data[0];
          console.log(this.product);
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.type === 'training-aids') {
      ProductService.getTrainingAid(this.id)
        .then(response => {
          this.product = response.data[0];
          console.log(this.product);
        })
        .catch(err => {
          console.log(err);
        });
    }

    if (this.type === 'test-wafers') {
      ProductService.getTestWafer(this.id)
        .then(response => {
          this.product = response.data[0];
          console.log(this.product);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
};
</script>

<template>
  <section class="py-4">
    <div class="row justify-content-center">
      <div class="col-lg-6 col-xl-4 col-xxl-3 text-center">
        <div class="card my-5 border-1 bg-light">
          <div class="card-body">
            <h1 class="display-1">404</h1>
            <h1 class="display-3 my-3">Sorry! Page Not Found ;(</h1>
            <h1 class="display-6 my-4">The link you followed is probably broken, or the page has been removed.</h1>

            <router-link :to="{ name: 'Home' }" class="btn btn-lg btn-secondary my-5">
              <h5>Return To Home</h5>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<template>
  <div class="-mx-5 mb-10">
    <!-- <img src="@/assets/img/solder-training-kits.jpg" class="w-full" /> -->
    [HEADER IMAGE HERE]
  </div>

  <div>
    <TrainingAidsLanding v-if="this.view == ''" />
    <TrainingAidsTools v-if="this.view == 'tools'" />
    <TrainingAidsSupplies v-if="this.view == 'supplies'" />
    <TrainingAidsItems v-if="this.view == 'training-aids'" />
  </div>

  <div>Current View: [{{ this.view }}]</div>
</template>

<script>
import TrainingAidsLanding from '@/components/training-aids/Landing.vue';
import TrainingAidsTools from '@/components/training-aids/Tools.vue';
import TrainingAidsSupplies from '@/components/training-aids/Supplies.vue';
import TrainingAidsItems from '@/components/training-aids/TrainingAids.vue';

export default {
  components: {
    TrainingAidsLanding,
    TrainingAidsTools,
    TrainingAidsSupplies,
    TrainingAidsItems,
  },
  props: {
    view: {
      type: String,
      default: '',
    },
  },
  methods: {
    playVideo(event) {
      console.log(event.target);
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>
